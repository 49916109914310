.event-lst-box{flex-wrap:wrap; flex-direction:row; grid-gap:15px}
 .card-itm{width:calc(100% - 0px); margin-bottom:40px; min-width: 310px;}
 .card-itm:last-child,
 .card-itm:nth-child(2){margin-top:40px}
 
 .card-itm .card{border-radius:10px; border:0px solid var(--common-border-color); box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06); padding-bottom:0px; background:#fff; z-index:1; transition:all 0.3s ease; overflow: hidden;}
  .card-itm .card-header{background:none; border:0; padding:0 20px; height:auto; margin:0 0 20px; display:none}
 .card-itm .card-content{padding:20px 25px;}
 .card-itm .card-footer{background:none; border:0; padding:0 25px; height:60px}
.card-itm .card .card-content p{font-size: var(--font-size-14); margin: 10px 0; display: -webkit-box;    -webkit-line-clamp: 4;    -webkit-box-orient: vertical;      overflow: hidden; min-height: 75px; line-height: 23px;}
.event-section .card-itm .card .card-content p{ display: -webkit-box;    -webkit-line-clamp: 3;    -webkit-box-orient: vertical;      overflow: hidden;}

.card-banner {   height: 210px !important;    width: 100%; overflow: hidden; background: #e1e6ec;}
.card-banner img{width:100%; height: 100%;  object-fit:cover}

/*.card-itm .card:hover{transform: scale(1.02 , 1.02); cursor: pointer;}
.without-shadow .card-itm .card:hover{transform: scale(1 , 1); cursor: pointer;}
.after-login-page .card-itm .card:hover{transform: scale(1 , 1); cursor: pointer;}*/

.after-login-page .publication-content .card-title{min-height: inherit;}
.after-login-page .card-itm .card .card-content p{min-height: inherit;}

 .event-lst-box.with-event-date  .card-itm .card-content{padding:0 26px 0px;}
 .with-event-date .card-itm .card-header{display:block}
 .with-event-date .card-category{display:none}
 .with-event-date .publication-date{display:none; color:rgba(0, 0, 0, 0.6); font-size: var(--font-size-12);}
 .with-event-date .card-action{display: block;}
 .card-category{display: block;}

.with-event-date .event-head{margin-top:-30px !important}


 .date-stats{width:70px; height:70px; border-radius:0px; overflow:hidden; box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06); border-radius:10px}
 .date-stats label{background:var(--color-white); font-size:var(--font-size-32); color:var(--theme-secondary-dark-bg); width:100%; height:calc(100% - 16px); margin:0px; padding:0px; text-align:center; font-weight: var(--font-weight-bold); line-height: 33px;}
 .date-stats label small{font-size: var(--font-size-12); display: block; font-weight: var(--font-weight-normal); color:var(--common-text-color); line-height: 17px;}
 .date-stats span{background:var(--theme-secondary-color); color:var(--color-white); font-size:var(--font-size-10);  width:100%; height:16px; line-height:16px; text-transform:uppercase; text-align:center}
 
 .time-stats{ margin-left:10px;  height: 40px;    line-height: 40px;     margin-top: 37px; width:auto; padding:0 5px}
 .time-stats label{ font-weight: var(--font-weight-medium); color:var(--theme-gray-light-color); font-size: var(--font-size-10); display: flex; align-items: flex-start; flex-direction: column; line-height: normal; white-space: nowrap;}
 .time-stats label strong{font-weight: var(--font-weight-semibold);  font-size: var(--font-size-12); color:var(--theme-base-color)}
 .time-stats span{font-size:var(--font-size-12);  font-weight:var(--font-weight-normal); color:var(--common-text-color); margin-left: auto;}
 
 .card-title{font-size:var(--font-size-18); font-weight:var(--font-weight-semibold); color:var(--theme-secondary-dark-bg); margin:0 0 0px; min-height: 50px;   display: -webkit-box;
    -webkit-box-orient: vertical;    -webkit-line-clamp: 3;    overflow: hidden;}
    .card-title a:hover,
    .card-title a:focus,
    .card-title:hover a{color:var(--theme-secondary-color) !important}

 .card-action{display: none;}

 .publication-content .card-banner{display: none;}
 .publication-content .card-category{color:var(--theme-secondary-color)}
 .publication-content .card-footer{height: 60px;}
 .publication-content .card-title{margin-bottom: 10px; min-height: inherit;}
 .publication-content .card-itm .card .card-content p{ min-height: inherit; line-height: 24px;}
 
 .publication-date{color:var(--theme-base-color); font-size: var(--font-size-12); margin-right: auto;}
 .publication-date i{margin-right: 5px; float: left; }
 .publication-content .card-action{display: block;}

 .application-date{color:var(--theme-base-color); font-size: var(--font-size-12); display: flex; align-items: center;}
 .application-date i{margin-right: 5px; float: left; }
 .application-date svg{width:14px}

.employment-page  .application-date{margin-right: auto;}
.employment-page  .publication-date{margin-right: 15px;}

.publication-by{color:var(--theme-base-color); font-size: var(--font-size-12); margin-right: auto; margin-left: 10px;}
.publication-by i{margin-right: 5px; float: left; }

.posted-status{color:var(--theme-base-color); font-size: var(--font-size-12); margin-right: auto;}
.posted-status i{margin-right: 5px; float: left; }

.publication-content-listing.publication-content .publication-date{margin:0}
.after-login-page .publication-content .publication-date{margin-right:10px}

 .employment-page .card-title{min-height: inherit;}
 .employment-page .card-itm .card .card-content p{min-height: inherit;}

 .employment-detail-page .card-title{min-height: inherit; font-size: var(--font-size-26); margin:0;}
 .employment-detail-page .card-itm .card .card-content .common-paragraph{margin:0px 0 10px; padding: 0; min-height: inherit; }
 .employment-detail-page .card-itm .card .card-content .common-paragraph.font-medium{font-weight: var(--font-weight-medium); margin: 0;}
 .employment-detail-page .publication-content .card-itm .card-content{padding:0}
 .employment-detail-page .publication-content .card-itm .card-footer{padding:0px; margin:0px; height:40px}

 .apply-btn .btn-green{height:50px; width:180px}

 section.event-section .domestic{color:var(--theme-secondary-color);}
section.event-section .international{color:var(--secondary-green-color);}

.international .date-stats span,
.international-tab-pane .date-stats span{background: var(--secondary-green-color);}
 .international .date-stats label,
.international-tab-pane .date-stats label{color: var(--secondary-green-color);}
/*.international .card-title,
.international-tab-pane .card-title{color: var(--secondary-green-color);} */


.event-lst-box.with-event-date .card{min-height: 300px;}
.event-lst-box.with-event-date .card-title{min-height: auto;}
.event-lst-box.with-event-date .common-paragraph{min-height: auto !important;}
.event-lst-box.with-event-date .card-content{min-height: 200px;}

.core-section .event-lst-box.with-event-date .card-content{min-height: auto;}
.core-section .event-lst-box.with-event-date .card{min-height: auto;}


.events-meeting-desc{width:calc(100% - 80px)}
.end-date{text-align: right;     display: flex;   flex-direction: row;   align-items: center;   white-space: nowrap;}
.end-date label{margin:0px ; padding: 0px; font-weight: var(--font-weight-medium);   font-size: 12px;   line-height: 18px; color:#9092A3 }
.end-date span{font-weight: 400;   font-size: 10px;   line-height: 15px;  width: 100%; text-align: center; color:#9092A3; white-space: nowrap; }
.events-meeting-desc .completed-date b{color:#1678FB; }
.end-date span b{color:#1678FB; font-weight: normal;}

.inner-tab-content .end-date{ align-items: flex-end;  }

.networking-content .card-banner{position: relative; z-index: -1;}
.networking-content .card-banner:after{background:  linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%); content:""; height:40px; width:100%; position: absolute; bottom: 0; left:0px; display: none;}

.detail-page-status .end-date label{color:#999999; margin-right:5px; font-size: 14px;}
.detail-page-status .end-date span{color:#999999; font-size: 12px;}

.events-meeting-for{ margin-bottom:5px;   height: 30px;  padding: 0;   display: flex; visibility: hidden;   text-align: right;   place-content: end;   align-items: end;   margin-right: -10px;}
.events-meeting-for span.international{ background: var(--secondary-green-color); text-align: center;    border-radius: 6px 6px 0px 0px; border-radius: 6px 6px 0px 0px; height: 20px; width:90px; font-size: 10px;line-height: 20px; font-weight: 500; color: #FFFFFF;}
.events-meeting-for span.domestic{ background: var(--theme-secondary-color) ; text-align: center;    border-radius: 6px 6px 0px 0px; border-radius: 6px 6px 0px 0px; height: 20px; width:90px; font-size: 10px;line-height: 20px; font-weight: 500; color: #FFFFFF;}

section.event-section .events-meeting-for{visibility: visible;}
.detail-page-status .post-time{font-weight: 600;   font-size: 12px;   line-height: 30px; color: #5A6370;}
.detail-page-status .post-time span{font-weight: normal;}


.network-meeting-tab-content .events-meeting-for{padding-top:10px}
.network-meeting-tab-content .events-meeting-for span{border-radius:6px;}