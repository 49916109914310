.rbt,
.rbt.has-aux{width:100%;  color: #212529; border-radius: var(--common-border-radius); padding:0px;     border: 1px solid var(--common-input-border); background-color: #fff;  display:flex }
.rbt input, .rbt .rbt-input,
.rbt.has-aux input{border:0px !important; border-radius: 0; height:42px !important}



span.sr-only{display: none !important;}
.rbt-token-removeable{padding:0px 10px; background-color: var(--theme-base-color); margin: 0px 5px 0 0 ; height: 30px; display: flex; align-items: center; font-size: var(--font-size-13); place-content: center; color:var(--color-white); font-weight: var(--font-weight-normal);}
.rbt-token .rbt-token-remove-button{border:0px !important; outline:none; box-shadow: none; position: relative; background: none; height: auto; top:inherit; right:inherit; width:auto; padding:0; margin-left: 5px; color:var(--common-maroon-color); font-weight: var(--font-weight-bold); font-size: var(--font-size-16); }
.rbt-input-multi .rbt-input-main{height: 32px !important; margin: 0;}
.has-aux .rbt-input{min-height: var(--common-input-height);    border-radius: var(--common-border-radius);    background: var(--input-bg-color) !important;
    border: none !important;    font-size: var(--font-size-14);}

.has-aux .rbt-input:hover,
.has-aux .rbt-input:focus{border:1px solid var(--input-focus-brdr-color) !important;    outline: none;      box-shadow: 0px 6px 9px 0px rgb(128 19 37 / 6%); /* background: var(--input-focus-bg-color) !important; */}

.rbt-input-multi .rbt-input-wrapper{grid-gap:0 5px;     height: 33px;     align-items: center; margin: 0; overflow: auto;}
.rbt-menu.dropdown-menu{border-radius: 0 0 10px 10px; border: 0px; box-shadow: 0px 6px 9px 0px rgb(0 0 0 / 20%); padding: 0px;  margin: -7px 0 0px;  min-width: 220px;  left: -1px !important;}
.rbt:after{ /*width: 0;  height: 0;  border-left: 10px solid transparent;  border-right: 10px solid transparent;  border-bottom: 10px solid #fff;  content: "";  position: absolute;  right: 13px;  bottom: -20px;*/}
.rbt-menu.dropdown-menu a{width: 100%; height: 45px;display: flex;align-items: center;font-size: var(--font-size-13);font-weight: var(--font-weight-normal);padding: 0;
  line-height: normal;min-width: 170px;border-bottom: 1px solid var(--common-input-border); padding:0 10px; color:var(--theme-base-color)}
  
  .rbt-menu.dropdown-menu a:hover{background:var(--theme-secondary-dark-bg) !important;}
.rbt-menu.dropdown-menu a:active,
.rbt-menu.dropdown-menu a.active,
.rbt-menu.dropdown-menu a:focus{background: var(--color-light-red) !important; color:var(--theme-base-color)  !important}

.rbt-menu.dropdown-menu.show{width: auto !important;}
.rbt-menu.dropdown-menu.show{top: 8px !important;}

.rbt-aux{    top: 6px; align-items: baseline;}
.rbt-aux .rbt-close {margin-top: 6px; margin-right: 43px; pointer-events: auto; position: relative; border: 0;    padding: 0 !important;  line-height: normal !important;  background: none; }
.rbt-aux .rbt-close {margin-right:55px; }

.rbt-aux .rbt-close span{width: 24px; height: 24px; align-items: center;  border-radius: 4px;    background: var(--common-maroon-bg); display: flex;  place-content: center;  color:#999;  font-size: 20px; padding:1px 10px 6px}

.input-group-text.loading-icon{background: var(--input-bg-color) !important ;}
.myloader {  border: 4px solid var(--color-primary-maroon);    border-top: 4px solid var(--color-primary-yellow);    border-radius: 50%;
    width: 22px;    height: 22px;    -webkit-animation: spin 2s linear infinite; /* Safari */    animation: spin 2s linear infinite;}
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .dropdown-item.active, .dropdown-item:active{color:var(--theme-secondary-color) !important}
  .header-action .dropdown-item.active, 
  .header-action .dropdown-item:active{color:var(--color-white) !important}
  em{color:var(--red-color)}

  .banner-content .rbt input, 
  .banner-content .rbt .rbt-input, 
  .banner-content .rbt.has-aux input{height:44px !important}
  .banner-content .rbt-menu.dropdown-menu.show {border-radius: 8px;}
  .banner-content .has-aux .rbt-input{padding-right: 54px;}
  .banner-content .rbt-aux .rbt-close{margin-right: 45px;}

  .has-aux .rbt-input{padding-right:60px}