
.column {  float: left;   column-width: auto;   width:17%  }
.column:first-child{width:32%}
.table-row:after { content: "";  display: table;   clear: both;  }
.price { list-style-type: none;  margin: 0; margin-right: 0px;   padding: 0;    -webkit-transition: 0.3s;    transition: 0.3s;  box-shadow: -6px 0px 6px rgba(0, 0, 0, 0.06) !important; }
.price .price-header { height: 60px; font-size: var(--font-size-20); font-weight: var(--font-weight-medium); color:var(--color-white)}

.column:nth-child(2) .price,
.column:nth-child(2) .price .price-header{border-radius:20px 0 0; }
.column:last-child .price .price-header{border-radius:0px 20px 0 0}
.column:last-child .price li{border-right:1px solid var(--common-border-color)}

.price li { border-bottom: 1px solid var(--common-border-color);  padding:0 20px;  display: flex; justify-content: center;   align-items: center;    height: 60px;    text-align: center; background: #fafafa; font-size: var(--font-size-18); color:var(--theme-base-color)}
.price li:nth-child(2n){background: #ffffff;}
.plan-details .price li{background: #ffffff;}
.plan-details .price li:nth-child(2n){background: #fafafa;}

.column:first-child .price li{/*justify-content: flex-start;*/}
.price .grey {  background-color: #eee;  font-size: 20px; }
.table-row:after {  content: "";  display: table;    clear: both;  }
.custom-action{grid-gap: 15px; margin-bottom: 30px;}
.custom-action .btn-green{padding: 0 25px !important; }
#section-signup .custom-action .btn-green{width:170px; height: 50px; border-radius:10px}

.pricing-page .page-title h1{font-size: var(--font-size-36);}

.plan-details{border:1px solid var(--common-border-color); border-radius:20px 0 0 20px; margin-top: 59px; overflow: hidden;}
.plan-details ul li:last-child{border-bottom:0px}

.plan-free .price-header{background: #61639B; border-radius:20px 0 0 0}
.plan-silver .price-header{background: #FF6E3F;}
.plan-gold .price-header{background: #EE5F7B;}
.plan-platinum .price-header{background: #903E81;; border-radius:0 20px 0 0 }

.price-action{height: 80px !important;}
.price-action .price-btn{width:170px; height: 50px; font-size: var(--font-size-18); color:var(--color-white); font-weight: var(--font-weight-semibold); line-height: 50px; border-radius:10px; transition: all 0.3s ease; border:0px}
.price-action .price-btn:focus,
.price-action .price-btn:hover{    color: var(--common-btn-text-color-hvr) !important;    background: var(--common-btn-bg-color-hvr) !important;}

.plan-free .price-action .price-btn{background: #61639B;}
.plan-silver .price-action .price-btn{background: #FF6E3F;}
.plan-gold .price-action .price-btn{background: #EE5F7B;}
.plan-platinum .price-action .price-btn{background: #903E81;}

.plan-platinum ul li{border-right:1px solid var(--common-border-color)}

.column:first-child .price li.plan-price{align-items:  flex-start;  justify-content: center; font-size: var(--font-size-22);  text-transform: capitalize;}
ul li.plan-price{height: 124px; display: flex; flex-direction: column; align-items:  center; position: relative; overflow: hidden;     box-shadow: -6px 0 6px rgba(0,0,0,.06)!important;}
.plan-details .price li{  font-size: var(--font-size-16); font-weight: var(--font-weight-semibold);  color:var(--theme-base-color); display: flex; flex-direction: column; align-items: flex-start; place-content: center;}

.item-description{display: block; color:var(--theme-gray-light-color); font-weight: var(--font-weight-normal); width:100%; text-align: left;}
.discount-price{font-size: var(--font-size-36); font-weight: var(--font-weight-bold); line-height: 36px;}
.package-price{font-size: var(--font-size-26); font-weight: var(--font-weight-medium); color:var(--theme-gray-light-color); line-height: 26px; text-decoration: line-through;}
.duration-time{font-size: var(--font-size-18); font-weight: var(--font-weight-medium); color:var(--theme-gray-light-color); line-height: 18px;}

.signup-form{margin-top:100px !important}
.signup-form .common-paragraph{margin-top:10px !important; margin-bottom:30px !important}


.price .mdi-close{font-size: 20px; color:#C4C4C4;}
.price .mdi-check{font-size: 20px; color:#5FBF24;}
.price .value-text{color:#61639b;}
.featured-ribbon {    width: 120px; height: 26px;  position: absolute;  top: 15px;  left: -30px;  text-align: center;    line-height: 26px;    font-size:var(--font-size-12);
    font-weight: var(--font-weight-semibold);  text-transform: uppercase;   color: var(--color-white); background: #5FBF24;    text-transform: uppercase;    transform: rotate(-45deg);    -webkit-transform: rotate(-45deg);}

    .price-action.active{pointer-events: none;}
    .price-action.active button{font-size: 0; position: relative; display: flex; flex-direction: row-reverse; place-content: center; grid-gap: 5px;}
    .price-action.active button:before{content:"Active Plan";    height: 50px;            font-size: var(--font-size-18);            color: var(--color-white);
            font-weight: var(--font-weight-semibold);}
            .price-action.active button:after{    content: "\F12C"; font-family: "Material Design Icons"; font-size: var(--font-size-18);            color: var(--color-white);
                font-weight: var(--font-weight-semibold);}
.features-list {padding: 0; margin: 0;}
.features-list li {font-size: var(--font-size-18); font-weight: var(--font-weight-medium); color: var(--theme-secondary-dark-bg); text-align: left; margin-bottom: 5px;}
.features-list li span {margin-right: 5px;}
.features-list-outer { margin-bottom: 64px; display: inline-flex;}


 
  .help-link .tooltiptext {    visibility: hidden;    max-width: 250px;    background-color: black; margin-left:10px;
    color: #fff; text-align: center;    padding: 10px;    border-radius:3px;    position: absolute;    z-index: 1; font-size: 12px; font-weight: normal;
  }
  .help-link .tooltiptext:empty{background: none; display:none}
  .help-link:hover .tooltiptext {
    visibility: visible;
  }