
@charset "utf-8";
/* CSS Document */
html{font-size:var(--theme-html-font-size);  font-size: var(--theme-body-font-size) !important;}
body{ font-family: var(--font-family-regular) !important;  font-weight: normal !important;  font-size: var(--theme-body-font-size) !important; overflow-x: hidden;}
li {    list-style: none;}
main{margin-top:var(--header-height);  position: relative; }
main .inner-page{margin-top:310px}

.inner-page{min-height: 950px; position: relative;}
.inner-page:before{background: url(../../asset/images/background/common-bg.svg) no-repeat center bottom; content:""; width:100vw; height:1400px; position: absolute; left:0; bottom:-160px; pointer-events: none;}

.ReactModal__Overlay .inner-page{min-height: inherit; padding:0}
.ReactModal__Overlay .inner-page:before{display: none;}

h1{border:0; padding:0; margin:0px}

div[class*="col-"]{position: relative;}
/* header css */
header{padding:0; background: var(--color-white); display:flex; align-items:center; height:var(--header-height); background: none;; transition: all 0.5s ease;}
.logo{min-width: 100px;}
.logo svg{width:95px; height:auto; transition:all 0.5s ease}
.logo svg path{fill:var(--color-white)}

.header-fixed header{background:#fff; box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06); height: 65px;}
.header-fixed header.inner-header:before{height:0}
.header-fixed header.inner-header:after{height:0}
.header-fixed .navbar-nav .nav-link{color:var(--common-text-color)}
.header-fixed .nav-link:hover, 
.header-fixed .nav-link:focus, 
.header-fixed .navbar-nav .nav-item:hover .nav-link,
.header-fixed .navbar-nav .nav-link:hover,
.header-fixed .navbar-nav .nav-link.active,
.header-fixed .navbar-nav .nav-link:focus{color:var(--theme-secondary-color) !important}
.header-fixed .navbar-nav .nav-item .nav-link.active:before,
.header-fixed .navbar-nav .nav-item .nav-link:hover:before,
.header-fixed .navbar-nav .nav-link:hover:before,
.header-fixed .navbar-nav .nav-link.active:before,
.header-fixed  .navbar-nav .nav-item .nav-link.active:before{background:var(--theme-secondary-color); width:calc(100% - 2px); bottom: 0;}

.navbar-nav .nav-link:hover:before,
.navbar-nav .nav-link.active:before,
.navbar-nav .nav-item .nav-link.active:before{background:var(--secondary-green-color); width:calc(100% - 2px); height: 2px; bottom: 15px;}

.navbar-nav .nav-item .nav-link.active:before,
.navbar-nav .nav-item .nav-link:hover:before{background:var(--secondary-green-color); width:calc(100% - 2px); height: 2px; bottom: 15px;}

.inner-page-title{position: relative; z-index: 4;  height: 90px;   transition: all 0.5s ease; pointer-events: none;}
.inner-page-title .pageTitle {  height: 100%;    display: flex;   align-items: center;    place-content: center;    margin: auto;    text-align: center;   transition: all 0.5s ease;}
.inner-page-title .pageTitle h1{margin:0px; color:var(--color-white) !important; font-size: var(--font-size-40) !important; font-weight: var(--font-weight-bold) !important;   transition: all 0.5s ease;}

.inner-header{position: relative; /*height:370px*/}
.inner-header:before{background:url(../../asset/images/background/inner-green-vector.png) no-repeat center bottom;background-size: cover; position: absolute; content:""; height:380px; width:100%; /*z-index: 2;*/ top: 0; display: block; transition: all 0.5s ease;  pointer-events: none;}
.inner-header:after{background:url(../../asset/images/background/inner-blue-vector.png) no-repeat center bottom; background-size: cover;  position: absolute; content:""; height:380px; width:100%; /*z-index: 1;*/ top:0; transition: all 0.5s ease; pointer-events: none;}

.header-fixed .inner-header{position: fixed; z-index: 9;}
.navbar{z-index: 4; display: flex; background: none;}
.navbar-nav .nav-link{padding:0px 10px !important; color:var(--header-link-color) ; font-weight: var(--font-weight-normal); font-family: var(--font-family-normal);transition: all 0.5s ease; white-space: nowrap; cursor: pointer; z-index: 1;}
.navbar-nav .nav-item{display: flex;   height: var(--header-height);  align-items: center; position: relative; }
.navbar-nav .nav-item .nav-item{ height: var(--header-height);  transition: all 0.5s ease;  }
.navbar-nav .nav-item .nav-link:before{position: absolute; bottom:0px; width:0; height: 3px;   content: ""; left:2px;  margin: auto;
     transition: width 0.5s ease;     z-index: -1;  }
     .navbar-nav .nav-item .nav-link:after{position: absolute; top:-20px; width:2px; height: 20px;   content: ""; left:2px; right:0; margin: auto;
        transition: all 0.5s ease;     z-index: -1; display: none; }



.header-fixed .navbar-nav .nav-item{height: 65px;}

.nav-link:hover, .nav-link:focus,
.navbar-nav .nav-item:hover .nav-link{color:var(--color-white) !important }

.header-fixed .logo svg{width:75px}
.header-fixed .logo svg path{fill:var(--theme-secondary-dark-bg)}

.header-fixed .inner-page-title{/*position: sticky; top:60px; background:#fafafa; height: 80px !important;*/ display: none;}
.header-fixed .inner-page-title .pageTitle{height: 80px !important; display: flex; align-items: center; }
.header-fixed .inner-page-title .pageTitle h1{font-size: var(--font-size-30) !important; height:auto; line-height:80px !important; color:var(--theme-secondary-dark-bg) !important}



.about-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-about-bg.png) no-repeat center top; height: 114px; }
.about-header .inner-page-title .pageTitle h1{padding-top:18px}

.networking-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-networking-bg.png) no-repeat center top; height: 122px; }
.networking-header .inner-page-title .pageTitle h1{padding-top:18px}

.events-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-events-bg.png) no-repeat center top; height: 122px; }
.events-detail-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-events-bg.png) no-repeat center top; height: 122px; }
.events-header .inner-page-title .pageTitle h1{padding-top:15px}

.publications-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-publications-bg.png) no-repeat center top 10px; height: 125px; }
.ldirectory-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-ldirectory-bg.png) no-repeat center top 10px; height: 125px; }
.gdirectory-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-gdirectory-bg.png) no-repeat center top 15px; height: 125px; }
.employment-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-employment-bg.png) no-repeat center top 10px; height: 125px; }
.ranking-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-ranking-bg.png) no-repeat center top 15px; height: 125px; }
.pricing-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-pricing-bg.png) no-repeat center top 15px; height: 125px; }
.signin-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-signin-bg.png) no-repeat center top 15px; height: 125px; }
.myprofile-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-myprofile-bg.png) no-repeat center top 15px; height: 125px; }
.editprofile-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-myprofile-bg.png) no-repeat center top 15px; height: 125px; }
.membership-checkout-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-membership-checkout-bg.png) no-repeat center top 15px; height: 115px; }
.contact-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-pricing-bg.png) no-repeat center top 15px; height: 125px; }
.upgraderanking-header .inner-page-title .pageTitle{background: url(../../asset/images/background/title-upgrade-ranking-bg.png) no-repeat center top; height: 107px; }


.sitemap  .inner-page-title .pageTitle{background: url(../../asset/images/background/title-networking-bg.png) no-repeat center top; height: 122px; }
.sitemap  .inner-page-title .pageTitle h1{padding-top:18px}


.header-action ul{grid-gap:13px;}

.main-header .inner-page-title{display: none;}
main.main-header{margin-top:70px}

.header-contacts ul{grid-gap:20px; margin-right:30px !important}
.header-contacts a{color:var(--theme-gray-dark-color); font-size: var(--font-size-14);}
.header-social ul{grid-gap:10px}
.header-social ul li a{font-size: 0; background:var(--theme-primary-color); width: 26px; height: 26px; display: flex; border-radius: 0%;  align-items: center;  place-content: center;}
.header-social ul li a img{width:11px}
.header-social ul li a img{width:11px}
.header-social ul li a img{width:11px}




.btn-primary,
button.btn-primary{width:100px; height: 40px; padding:0px; line-height: normal; border:2px solid transparent !important; background: var(--secondary-green-color); color:var(--color-white) !important; font-size: var(--font-size-14); font-weight: var(--font-weight-regular); transition: all 0.5s ease; display: flex; align-items: center; place-content: center; border-radius:var(--common-border-radius); white-space: nowrap;}

.btn-green{min-width:100px; height: 50px; border:0px; padding:0px; line-height: normal;  background: var(--secondary-green-color); color:var(--color-white) !important; font-size: var(--font-size-20); font-weight: var(--font-weight-semibold); transition: all 0.5s ease; display: flex; align-items: center; place-content: center; border-radius: var(--common-border-radius);}
.right-search-block ul li:hover a.btn-green,
.btn-login{width:72px; height: 38px; padding:0px; line-height: normal; background: var(--secondary-green-color); color:var(--color-white); font-size: var(--font-size-14); font-weight: var(--font-weight-regular); transition: all 0.3s ease;     display: flex;
    align-items: center;    place-content: center;  border-radius: var(--common-border-radius);}

.navbar-right .header-action a.btn-green:hover{width:72px; height: 38px; padding:0px; line-height: normal; background: transparent !important; color:var(--color-white); 
    font-size: var(--font-size-14); font-weight: var(--font-weight-regular); transition: all 0.3s ease;     display: flex;
    align-items: center; border:1px solid var(--secondary-green-color);   place-content: center;  border-radius: var(--common-border-radius);}

.networking-detail-page .right-search-block ul li:hover a.btn-green, 
.networking-detail-page .btn-login{width: 100%; height:50px; color:#fff !important;     font-size: var(--font-size-20) !important;}

.btn-whatsup{width:36px; height: 36px; border-radius:10px; background: #25D366; text-align: center; line-height: 32px}
.btn-whatsup:hover{background: #02ad42;}
.btn-secondary,
button.btn-secondary{min-width:100px; height: 60px; padding:0px; line-height: normal; background: var(--secondary-green-color) !important; color:var(--color-white) !important; font-size: var(--font-size-14); font-weight: var(--font-weight-regular); transition: all 0.5s ease; display: flex; align-items: center; place-content: center; border-radius:var(--common-border-radius);}

.btn-border{border:1px solid var(--btn-brdr-color); color:var(--btn-text-color);
     background: var(--btn-bg-color); font-size: var(--font-size-12); font-weight: var(--font-weight-medium); transition: all 0.5s ease;}

.btn-login:hover, .btn-login:focus,
.btn-green:hover, .btn-green:focus,
.cmn-btn:hover, .cmn-btn:focus,
.btn-primary:hover, .btn-border:hover{ color:var(--common-btn-text-color-hvr) !important; background: var(--common-btn-bg-color-hvr) !important; }
.btn-login {width: 150px;}
.btn-view{min-width:120px; grid-gap:5px; border:1px solid var(--theme-base-color); padding:0 10px; height: 36px; border-radius: var(--common-border-radius); display: flex;    align-items: center;    place-content: center;    justify-content: space-evenly; color:var(--common-text-color); white-space: nowrap;}
.btn-view:hover svg path,
.btn-view:focus svg path{fill:#ffffff}
.btn-search{width:100px; height: 40px;}



section.event-section .btn-view{width:120px}

.modal-action{padding:20px 100px; display: flex; grid-gap:20px; flex-direction: column;}
.modal-action .cmn-btn{height: 60px !important; width:100% !important; font-size: var(--font-size-20) !important;}


/* banner section */

.banner-image-block{width: 663px;   height: 560px; margin:auto; z-index: 4; position: relative;  display: flex; align-items: center; place-content: center;}

.banner-image{position:relative;  width: 663px;   height: 560px;  z-index:2;  }
.banner-image img{position:relative; mask-image: url(../../asset/images/background/banner-image-bg.svg?v1);    mask-mode: alpha;    mask-repeat: no-repeat;
    display: block;    width: 100%;  object-fit: cover;  height: auto;    -webkit-mask-image: url(../../asset/images/background/banner-image-bg.svg);    mask-image: url(../../asset/images/background/banner-image-bg.svg);
    -webkit-mask-position: top left;    mask-position: top left;    -webkit-mask-repeat: no-repeat;    mask-repeat: no-repeat;    -webkit-mask-size: 663px 560px;    mask-size: 663px 560px;}

.banner-section{background: var(--banner-bg); height:690px; padding:20px 0; overflow:hidden; margin:0; }
.banner-section:before{background:url(../../asset/images/background/banner-green-vector.png?v1) no-repeat center bottom;  position: absolute; content:""; height:753px; width: 1920px; /*z-index: 2;*/ top: -80px; left:0; right:0; margin: auto;}
.banner-section:after{background:url(../../asset/images/background/banner-blue-vector.png?v1) no-repeat center bottom;   position: absolute; content:""; height:753px; width:1920px; /*z-index: 1;*/ top:-80px; left:0; right:0; margin: auto;}

.banner-section .background-vector{ z-index: 3;  position: absolute;   top: 0; pointer-events: none;}
.banner-section .background-vector svg{width:100%; height:auto}

.banner-section .banner-title{margin-top:50px}
.banner-section .banner-title h1{font-size: var(--banner-heading-text); color:var(--banner-heading-color); margin:0; padding:0; line-height:normal; font-weight: var(--font-weight-extrabold); font-family: var(--font-family-semibold); display:flex; align-items:center}
.banner-section .banner-content{position: relative; z-index:4;}
.banner-section .banner-content p{font-size:var(--banner-paragraph-font-size); color:var(--banner-pararaph-color); margin:0px; padding: 0;}

/* common service section */
section .page-title{margin-bottom: 70px;}
section .page-title .title-text{font-size: var(--title-text-font-size);   margin:0 0 30px; padding:0px; line-height: normal; font-weight: var(--font-weight-bold); font-family: var(--font-family-bold); border:0; color:var(--common-text-color)}
.pricing-page .page-title .common-paragraph{font-size: var(--font-size-16); margin:30px auto 50px; max-width: 65%;}
section .page-title .common-paragraph{font-size: var(--font-size-16); margin:30px auto; max-width: 65%;}
section .page-title .paragraph-text{font-size: var(--paragraph-text-font-size); color:var(--theme-base-color); font-weight: var(--font-weight-normal);  margin: auto; line-height: normal;}
p,
.common-paragraph{font-size: var(--font-size-15) !important; color:var(--common-text-color); font-weight: var(--font-weight-normal) !important;  margin: auto; line-height: 24px;     white-space: pre-wrap;}


/*events section */
.event-section{margin:0; padding:0 0 100px; position: relative; overflow: hidden;}
.event-section:before{content:""; background:  url(../../asset/images/background/events-bg-lft.svg) no-repeat center center; width:187px; height:481px; left:-90px; top:0px; position: absolute;}
 .event-section:after{content:""; background:  url(../../asset/images/background/events-bg-rgt.svg) no-repeat center center; width:233px; height:287px; right:-115px; top:120px; position: absolute;}

.event-section .page-title {margin:0px}
.event-section .page-title small{font-size: var(--font-size-16); font-weight: var(--font-weight-medium);}
.event-section .page-title h1{font-size:var(--font-size-36); font-weight:var(--font-weight-bold); width:100%; color:var(--theme-secondary-dark-bg); }

.tabs-links ul.tabs {	 display: inline-flex !important;	 vertical-align: top;	 position: relative;	 z-index: 10;	 margin: auto;	 padding: 0;	 width: auto;
	 min-width: 175px;	 list-style: none;	 -ms-transition: all 0.5s ease;	 -webkit-transition: all 0.5s ease;	 transition: all 0.5s ease; border:0}
.tabs-links ul.tabs li{margin:0 9px}
.tabs-links ul.tabs li .nav-link{margin: 0 0 10px;	 cursor: pointer;	 padding: 0px 15px;	 line-height: 50px;	 text-align: left; font-weight: var(--font-weight-normal);
    background: #FFFFFF;    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.06) !important;  transition: all 0.2s ease;    border-radius: var(--common-border-radius); border:none; 	font-size:var(--font-size-16); color:#5A6370; }
 .tabs-links ul.tabs li .nav-link:focus,
 .tabs-links ul.tabs li .nav-link:hover { background: var(--secondary-green-color); color: var(--color-white) !important;	transition: all 0.2s ease;}
.tabs-links ul.tabs li .nav-link.active {background: var(--secondary-green-color);	 color: var(--color-white); 	 transition: all 0.2s ease;}
.event-section .tab_container { display: inline-block;  vertical-align: top; position: relative; z-index: 20;	 width: 100%;	 text-align: left;}
.event-section .tab_content { padding:0;	 height: 100%;	}
.event-section .tab_drawer_heading {	 display: none;}
 
.inner-tab-content{margin: 20px 0;}
.tab-inner-content h2{display: none;}
 
 /*core section */
 .core-section{padding:50px 0 100px; position: relative; overflow: hidden;}
 .core-section:before{content:""; background:  url(../../asset/images/background/corevalue-bg-lft.svg) no-repeat center center; width:227px; height:315px; left:-120px; top:70px; position: absolute;}
 .core-section:after{content:""; background:  url(../../asset/images/background/corevalue-bg-rgt.svg) no-repeat center center; width:160px; height:391px; right:-80px; top:180px; position: absolute;}

 .core-content .event-lst-box{grid-gap: 30px 15px;}
  .core-content .card-itm{width:calc(50% - 15px); margin:0px !important}
 .core-content .card-itm .card{transition:all 0.5s ease; display: flex;    flex-direction: row; border-radius:20px}
 .core-content .card-itm .card .card-header{width:200px; padding:0px; margin:0px}
 .core-content .card-itm .card .card-content{width:calc(100% - 240px); display: flex; flex-direction: column; border-bottom:4px solid transparent}
 .core-content .card-itm .card .card-content p{margin:0}
 .core-head{width:200px; height:180px; border-radius:0px; display:flex; align-items:Center; margin:0; place-content:center; transition:all 0.1s ease}

 .core-head img{mask-image: url(../../asset/images/background/corevalue-vector.svg);    mask-mode: alpha;    mask-repeat: no-repeat;
    display: block;    width: 200px;    height: 180px;    -webkit-mask-image: url(../../asset/images/background/corevalue-vector.svg);    mask-image: url(../../asset/images/background/corevalue-vector.svg);
    -webkit-mask-position: top left;    mask-position: top left;    -webkit-mask-repeat: no-repeat;    mask-repeat: no-repeat;    -webkit-mask-size: 200px 180px;    mask-size: 200px 180px;}
 
 .card-itm.core-itm-01 .card-content{border-color:#0DB5AB !important;}
 .card-itm.core-itm-02 .card-content{border-color:#FFDC96 !important;}
 .card-itm.core-itm-03 .card-content{border-color:#80B2F5 !important;}
 .card-itm.core-itm-04 .card-content{border-color:#FE8282 !important;}
 
 .event-itm:hover .card .core-head{background:rgba(255,255,255,0.5) !important;}
 
/*howitwork section */
.howitwork-section{padding:220px 0; position: relative; height:918px; overflow: hidden;}

.howitwork-section:before{background:url(../../asset/images/background/howitwork-blue-vector.svg) no-repeat center top; position: absolute; content:""; height:100%; width:100%; z-index: 2; top: 0;}
.howitwork-section:after{background:url(../../asset/images/background/howitwork-green-vector.svg) no-repeat center top; position: absolute; content:""; height:100%; width:100%; z-index: 1; top:0}

.howitwork-section .background-vector{ z-index: 3;  position: absolute;   top: 0;}
.howitwork-section .background-vector svg{    width: calc(100% + 180px);    height: auto;    margin-left: -90px;}

section.howitwork-section .page-title .title-text{color:var(--color-white) !important}
section.howitwork-section .page-title p.common-paragraph{color:var(--color-white) !important}

.tabs_wrapper{position: relative; z-index: 4;}

.custom-lst-box{flex-wrap:wrap; flex-direction:row; grid-gap:30px; display:flex; position:relative; z-index:1; width:100%}
.custom-lst-box .custom-itm{width:calc(33.33% - 30px); margin:0px !important;}
.splide{width:100%}

.custom-itm-content{position:relative; border-radius:0px; overflow:hidden}
.custom-itm-content iframe{border-radius: 20px; width:100%}
.custom-itm-content img{max-width:100%}
.custom-itm-content:before{background:rgba(0,0,0,0.2); position:absolute; width:100%; height:100%; content:""; left:0; top:0; display: none;}

.custom-content-box .back-dot-bg.rightTop {top: -72px;    right: -60px;}
.custom-content-box .back-dot-bg.rightTop svg path{fill:#e5e5e5}
    
.custom-content-box .back-dot-bg.leftBottom { bottom: -72px;   left: -60px;}
.custom-content-box .back-dot-bg.leftBottom svg path{fill:#e5e5e5}

.play-action{height:40px; width:60px; position:absolute; margin:auto; top:0; bottom:0; left:0; right:0}
 

 
/*testimonial section */
.testimonial-section{padding:50px 0 100px; position: relative; overflow: hidden;}
.testimonial-section .custom-lst-box .custom-itm{width:100%;}
.testimonial-section:before{content:""; background:  url(../../asset/images/background/testimonial-bg-lft.svg) no-repeat center center; width:210px; height:247px; left:-105px; top:20px; position: absolute;}
.testimonial-section:after{content:""; background:  url(../../asset/images/background/testimonial-bg-rgt.svg) no-repeat center center; width:359px; height:287px; right:-175px; top:180px; position: absolute;}


/* footer */
.footer{background: var(--footer-bg); position: relative; }
/*main:before{background:url(../../asset/images/background/inner-page-left-bottom-bg.svg) no-repeat center center ; content:""; position: absolute; left: -122px;   top: -300px;    width: 262px;    height: 414px;    z-index: -1;}
main:after{background:url(../../asset/images/background/inner-page-left-bottom-right.svg) no-repeat center center ;  content:"";    position: absolute;  right: -58px;    top: -112px;    width: 233px;    height: 287px;    z-index: -1;}
*/
.ftr-desc p,
.ftr-desc .common-paragraph{color:var(--ftr-text-color) !important; font-size: var(--font-size-12) !important; line-height: 22px !important; font-weight: var(--font-weight-normal) !important;}

.ftr-link-lst svg{width:95px; height:63px}
.ftr-link-lst svg path{fill:var(--color-white)}
.ftr-link-lst ul li{margin-bottom: 13px;}
.ftr-link-lst .ftr-communication{margin:0px}
.ftr-link-lst.footer-about ul li{margin-bottom: 5px;}
.ftr-link-lst .ftr-communication ul li:nth-child(2){margin:0px}
.ftr-link-lst ul li a{height:25px; line-height: 25px; color:var(--ftr-text-color) !important; font-weight: var(--font-weight-normal); font-family: var(--font-family-regular);}
.ftr-link-lst ul li a:hover{color:#fff !important}
.ftr-link-lst ul li i.mdi{font-size: var(--font-size-18);}
.copyright p{color:var(--ftr-text-color); padding: 0; margin: 0; font-family: var(--font-family-regular) !important; }
.quick-links h2{color:var(--color-white); font-size: var(--font-size-18); font-weight: var(--font-weight-semibold); font-family: var(--font-family-semibold); margin-bottom: 25px;}

.quick-links li .svg,
.quick-links li svg{width:10px; height:10px; margin-right:10px !important}
.quick-links li a svg path{fill:var(--theme-secondary-color)}

.ftr-social{width:180px}
.ftr-social ul li a{color:var(--ftr-text-color) !important}
.ftr-social ul li a i{font-size: var(--font-size-20) !important; }
.ftr-social ul li a:hover i{ color:#fff}

.ftr-desc{margin: 10px 0 0;}

.ftr-communication{margin: 10px 0;}
.ftr-communication li *{font-size: var(--font-size-12);}
.ftr-communication li strong{color:var(--ftr-text-color); font-weight: var(--font-weight-normal); margin:0 5px 0 0}

.ftr-bottom{padding:30px 0; border-top:1px solid rgba(255,255,255,0.12); margin-top:30px !important}

.banner-section .search-block{width:535px !important; height:365px; background: transparent url(../../asset/images/background/home-search-bg.png?v1) no-repeat center top !important; box-shadow: none;}
.right-block-content{background: rgba(255, 255, 255, 0.06);    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.03);    border-radius: 20px;	box-sizing: border-box; padding:0px 30px !important; width:100% !important; height:auto; }
.search-block{background: rgba(255, 255, 255, 0.06);    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.03);    border-radius: 20px;	box-sizing: border-box; padding:27px 30px !important; width:100% !important; height:auto; margin-top: 20px !important; }
.search-action{margin-top: 10px; text-align: right}
.search-block .form-row label{color:var(--color-white)}
.search-block .form-group{display: flex; flex-wrap: wrap; flex-direction: row; grid-gap: 0;}
.search-block .search-action{width:100%}
.search-block .btn-primary{width:100%}

.banner-section .search-block .form-row label{margin-bottom:2px !important;}

.right-search-block .search-block .btn-primary{height: 50px; font-size: var(--font-size-20); font-weight: var(--font-weight-semibold);}

.search-block .form-group .form-row{width:calc(100% - 0px); margin-bottom: 10px !important;}
.banner-section .search-block .form-group{grid-gap:10px 15px}
.banner-section .search-block .form-group .form-row{width:calc(50% - 15px);margin-bottom: 0 !important;}
.banner-section .search-block .btn-primary{width:100px;}
.banner-section .search-block .search-action{width:50%; margin-top:10px; padding-right: 15px; display: flex; justify-content: flex-start;}


.splide__pagination{bottom:-50px !important}
.splide__pagination li{margin:0 5px !important}
.splide__pagination li button{width:16px; border-radius: 5px; height: 5px; background: #C4C4C4;}
.splide__pagination li button.is-active{width:26px; border-radius: 5px; height: 5px; background: var(--theme-secondary-color);}


.inner-page{padding:0 0 50px 0; }
.custom-page-center{min-height:700px; display: flex; align-items: center; }

.inner-head{padding:0; height:80px; align-items: center;}

.page-title{width:100%;}
.page-title small{color:var(--theme-base-color); font-size: var(--font-size-16); font-weight: var(--font-weight-medium);}
.page-title h1{font-size: var(--font-size-48); font-weight: var(--font-weight-bold); color:var(--theme-secondary-dark-bg) !important}
.page-title h2{font-size: var(--font-size-36); font-weight: var(--font-weight-bold); color:var(--theme-secondary-dark-bg)}
.page-title h3{font-size: var(--font-size-16); font-weight: var(--font-weight-semibold); color:var(--theme-base-color)}
.page-bullet-points{margin-bottom: 25px;}
.howitwork-section .page-title small{color:var(--secondary-green-color); }

.membership-page .page-title.secondary-page-title{margin: 30px 0 !important;}

.inner-page.about-page{padding-top:50px}
.about-page .page-title{margin-bottom: 50px;}

.inner-sub-heading{color:var(--theme-base-color); font-size: var(--font-size-18); font-weight: var(--font-weight-semibold);}

.about-banner{float: left; width:500px; position: relative; padding-left:60px; padding-top:10px; margin-right: 15px;}
.about-banner:before{background:url(../../asset/images/background/about-image-bg.png?v1) no-repeat left  top  ; position: absolute;     left: -35px;  bottom: -40px; content:""; width:255px; height: 321px; z-index: -1;}
.about-banner img{border-radius: 20px;}
.about-banner .back-dot-bg.leftTop{top:-60px; left:-10px; z-index: -1;}

.about-banner .back-dot-bg.rightBottom{right:-55px; bottom:-60px; z-index: -1;}
.long-description p strong,
.short-description p strong{/*color:var(--theme-secondary-dark-bg); font-size:var(--font-size-30); font-weight: var(--font-weight-light);*/}
.long-description{margin-top: 100px; padding:0 50px}

.common-page-content .detail-page-title.page-title h2{line-height: 36px;}
.common-page-content .common-paragraph{margin-bottom: 20px; line-height: 33px;}

.search-field .form-row input{height: 40px; width:300px; border-radius:var(--common-border-radius)}
.form-row .form-input-div i.search-icon{position: absolute; right:15px; top:6px}

.head-tabs{height: 40px; margin-right:10px}
.head-tabs .nav-tabs{border:0px;     flex-wrap: nowrap;}
.head-tabs .nav-tabs .nav-item .nav-link{border-radius: 0; border:1px solid var(--common-border-color)  !important; width:130px; color:var(--theme-base-color) !important; height:40px; font-size: var(--font-size-14); font-weight: var(--font-weight-medium);}
.head-tabs .nav-tabs .nav-item .nav-link.active{background: var(--secondary-green-color); color:#fff !important}
.head-tabs .nav-tabs .nav-item:first-child .nav-link{border-right:0px !important; border-radius: var(--common-border-radius) 0 0 var(--common-border-radius);}
.head-tabs .nav-tabs .nav-item:last-child .nav-link{border-left:0px !important; border-radius: 0 var(--common-border-radius) var(--common-border-radius) 0;}

.tabs-links ul.tabs li .nav-link.domestic-tab-link:hover,
.tabs-links ul.tabs li .nav-link.domestic-tab-link.active,
.head-tabs .nav-tabs .nav-item .nav-link.domestic-tab-link.active{background: var(--theme-secondary-color);}



.networking-content{padding-top:30px;  }
.networking-content .card-banner{height: 210px;}
.networking-content .card-itm:last-child, 
.networking-content .card-itm:nth-child(2){margin-top: 0;}
.networking-content .event-lst-box{grid-gap:30px}
.networking-content .card-itm{    width: calc(33% - 30px); margin-bottom:15px !important}
.networking-content .event-lst-box .btn-view{width:120px}

.network-meeting-tab-content .card-banner{display: none;}
.network-meeting-tab-content .with-event-date .event-head{margin-top:30px !important}
.network-meeting-tab-content .with-event-date .card-itm .card-header{background:url(../../asset/images/background/card-background-bg.png) no-repeat center top; }
/* 
.network-meeting-tab-content .with-event-date .card-itm.international .card-header{background:url(../../asset/images/background/card-background-bg-international.png) no-repeat center top; }
.network-meeting-tab-content .international-tab-pane .with-event-date .card-itm .card-header{background:url(../../asset/images/background/card-background-bg-international.png) no-repeat center top; } */

.publication-content{padding-top:30px;  }
.common-page-content.publication-content .common-paragraph{margin:0px}
.publication-content .card-itm .card{padding-bottom: 0;}
.publication-content .card-itm{    width: calc(100% - 0px); margin:0 0 10px !important}
.publication-content .card-itm .card-content{padding-bottom: 0;}
.publication-content .card-itm .card-footer{height: 60px; display: flex; align-items: flex-end; border-top:1px solid var(--common-border-color); margin-top: 20px; flex-direction: row-reverse;}


.employment-page .publication-content .card-itm .card-footer{align-items: center;}
.jobopportunity-page .publication-content .card-itm .card-footer{align-items: center;}
.jobopportunity-page.after-login-page .publication-content .publication-date{margin-right: auto;}

.right-search-block{margin-top:0px; box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06); border-radius: 20px; background: white; }
.right-block-title{background:url(../../asset/images/background/card-background-bg.png) no-repeat center top;  color:var(--right-block-title-color);  height:120px; display: flex; place-content: center; background-size: 100% 110px}
.right-block-title h2{font-size: var(--font-size-36); font-weight: var(--font-weight-semibold); margin:12px 0px 0 ;padding:0; display: flex; flex-direction: column; color:var(--theme-secondary-dark-bg); align-items: center; line-height: 30px;}
.right-block-title h2 span{font-size: var(--font-size-18); font-weight: var(--font-weight-regular); font-weight: var(--font-weight-normal);}

.search-filter{ padding:10px;}
.search-filter .search-field .form-row input{width:100%}

.right-search-block .card{border-radius:0px; padding:10px 30px; border:none; background: none;}
.right-search-block .card .card-content{max-height: 1280px; overflow-y: auto; overflow-x:hidden; margin-bottom: 35px;}
.right-search-block ul{border:0px}
.right-search-block ul li{margin:15px 0;}
.right-search-block ul li button,
.right-search-block ul li a{font-size: var(--font-size-18); line-height: 26px; font-weight:var(--font-weight-normal); color:var(--common-text-color); border:0px !important; width:100%; text-align: left; display:flex; grid-gap:15px; padding:0px;}
.right-search-block ul li:hover button,
.right-search-block ul li:hover a{color:var(--theme-secondary-color) !important}
.right-search-block ul li button.active{color:var(--theme-secondary-color) !important}

.employment-detail-page .right-search-block ul li button, 
.employment-detail-page .right-search-block ul li a{border:1px solid var(--theme-base-color) !important; min-width: 120px !important; height: 36px !important; font-size: var(--font-size-12) !important;}

.after-login-page .right-search-block ul li{border-bottom: 1px solid var(--common-border-color);}
.after-login-page .right-search-block ul li:last-child{border:0}

div[class*=ReactFlagsSelect-]{background: rgba(255,255,255,0.06); border-radius: var(--common-border-radius) !important; border:0 ; margin: 0; padding: 0 ; transition: all 0.5s ease; pointer-events: none;}
div[class*=ReactFlagsSelect-] button{color:var(--color-white); border-radius: 0; border:0; padding:0px 10px; height:38px; line-height: 38px; font-size: var(--font-size-12) !important; font-weight: var(--font-weight-medium); text-transform: uppercase; min-width: 90px; transition: all 0.5s ease;}
div[class*=ReactFlagsSelect-] button:after{ border-top-color:var(--color-white) !important; transition: all 0.5s ease; display: none;}

.header-fixed div[class*=ReactFlagsSelect-]{background: rgba(0, 0, 0, 0.06); }
.header-fixed div[class*=ReactFlagsSelect-] button{ color:var(--common-text-color) !important}
.header-fixed div[class*=ReactFlagsSelect-] button:after{ border-top-color:var(--common-text-color) !important}

.networking-detail-content{max-width: 95%;}
.detail-page-title.page-title h2{font-size: var(--font-size-26); font-weight: var(--font-weight-semibold); }
.detail-page-status{ border-top:1px solid var(--common-border-color)}

.detail-content-top{margin-bottom: 30px;}
.highlighted-text{color:var(--theme-secondary-dark-bg); font-size: var(--font-size-18); font-weight: vaR(--font-weight-semibold);}

.publication-detail-content .post-type,
.publication-detail-content .post-type .highlight-text{font-size: var(--font-size-12);}


.networking-detail-page ul li{font-size: var(--font-size-18); font-weight: var(--font-weight-normal); margin:5px 0; border-bottom: 1px solid var(--common-border-color); }
.networking-detail-page ul li:last-child{border:0px}
.networking-detail-page ul li div{display: flex; align-items: center; height: 57px; color:var(--theme-gray-light-color); white-space: nowrap; font-size: var(--font-size-16);}
.networking-detail-page ul li svg,
.networking-detail-page ul li img{width:22px; margin-right:10px; display: inline-block; }
.networking-detail-page ul li strong{font-size: var(--font-size-26); color:var(--common-text-color); margin-right:5px}
.networking-detail-page ul li .reg-date strong{font-size: var(--font-size-18); font-weight: var(--font-weight-semibold);}
.networking-detail-page ul li .reg-type strong{font-size: var(--font-size-18); font-weight: var(--font-weight-semibold);}
.networking-detail-page ul li .btn-green{font-size: var(--font-size-20); border-radius: var(--common-border-radius); margin:20px 0; width:100%}
.networking-detail-page ul li .common-paragraph{font-size: var(--font-size-12); text-align: center; line-height: 23px; margin-top: 15px;}

.event-banner{width:100%; /*height: 320px; */ height: auto; border-radius: 20px; margin-bottom: 30px; overflow: hidden;}
.event-banner img{object-fit: cover; width:100%; /*height: 320px; */ height: auto; }

.ldirectory-page .right-block-title{height:auto}
.ldirectory-page .search-block{margin-top:0px !important; }
.ldirectory-page .search-block .form-row label{display: none !important;}

.gdirectory-page .right-search-block{height:auto}
.gdirectory-page .right-block-title{height:auto}
.gdirectory-page .search-block{margin-top:0px !important; }
.gdirectory-page .search-block .form-row label{display: none !important;}

.card-content-top{margin-top: 10px;}
.card-content-top .card-title{margin:0px; padding: 0;}
.card-content small{color:var(--theme-base-color); font-weight: var(--font-weight-medium); margin-bottom: 20px !important; display: flex;}
.card-content-top .post-type,
.card-content-top .post-type .highlight-text{font-size: var(--font-size-12); grid-gap:5px; display: flex;}

.ranking-page .page-title{margin-bottom: 1px !important;}
.ranking-page .page-title h1{font-size: var(--font-size-36); text-transform: capitalize;}

.ranking-itm{padding:50px 0}
.ranking-content h2{font-size: var(--font-size-26); font-weight: var(--font-weight-semibold); margin-bottom: 20px; display:flex; flex-direction:column; color:var(--theme-secondary-dark-bg) !important}
.ranking-content h2 span{font-size: var(--font-size-20); font-weight: var(--font-weight-medium); color:var(--theme-base-color); margin:30px 0 5px}
.ranking-description ul{margin:0px; padding: 0;}
.ranking-description ul li{background: #FFFFFF;  box-shadow: 0px 12px 25px rgba(85, 74, 74, 0.06); border-radius: 20px;  font-size: var(--font-size-20); font-weight: var(--font-weight-medium); margin-bottom:10px; color:var(--theme-base-color); padding:10px 20px}


.ranking-upcoming .ranking-content h2{color:#FB9650}
.ranking-experianced .ranking-content h2{color:#F579A0}
.ranking-recommended .ranking-content h2{color:#16C4FB}
.ranking-leading .ranking-content h2{color:#A765DB}
.ranking-expert .ranking-content h2{color:#3ACCA0}


.highlighted-text{background:#FFFFE0 !important; color:#222222 !important}

.gcdirectory-detail-page .card-left{width:120px}
.gcdirectory-detail-page .card-mdl{width:calc(100% - 300px) }
.directory-detail-page .card-right{width:320px}


.directory-detail-page .cmn-list{align-items: flex-end;}
.directory-detail-page .without-shadow .emp-award{margin-bottom: 10px; justify-content: flex-end; grid-gap:15px; width:auto}

.emp_ranking{display: flex; align-items: center; flex-direction: column;}
.emp_ranking h4{font-size: var(--font-size-10); margin:5px 0; text-align: center;}

.emp_ranking.ranking-color-0 h4{color:#FB9650}
.emp_ranking.ranking-color-1 h4{color:#F579A0}
.emp_ranking.ranking-color-2 h4{color:#16C4FB}
.emp_ranking.ranking-color-3 h4{color:#A765DB}

.ranking-color-0.ranking-itm .ranking-content h2{color:#FB9650}
.ranking-color-1.ranking-itm .ranking-content h2{color:#F579A0}
.ranking-color-2.ranking-itm .ranking-content h2{color:#16C4FB}
.ranking-color-3.ranking-itm .ranking-content h2{color:#A765DB}
.ranking-color-4.ranking-itm .ranking-content h2{color:#3ACCA0}

.ranking-badge .ranking-color svg,
.ranking-badge .ranking-color img{filter:grayscale(100%)}

.ranking-badge .ranking-color-0 svg circle{fill:#FB9650}
.ranking-badge .ranking-color-1 svg circle{fill:#F579A0}
.ranking-badge .ranking-color-2 svg circle{fill:#16C4FB}
.ranking-badge .ranking-color-3 svg circle{fill:#A765DB}
.ranking-badge .ranking-color-4 svg circle{fill:#3ACCA0}

.ranking-badge .ranking-color-0 .icon-badge img{background:url(../../asset/images/badge-small-00.svg); width:16px;   padding-left: 16px;   height:16px;    box-sizing:border-box;}
.ranking-badge .ranking-color-1 .icon-badge img{background:url(../../asset/images/badge-small-01.svg); width:16px;  padding-left: 16px;   height:16px;    box-sizing:border-box;}
.ranking-badge .ranking-color-2 .icon-badge img{background:url(../../asset/images/badge-small-02.svg); width:16px;  padding-left: 16px;    height:16px;    box-sizing:border-box;}
.ranking-badge .ranking-color-3 .icon-badge img{background:url(../../asset/images/badge-small-03.svg); width:16px;  padding-left: 16px;    height:16px;    box-sizing:border-box;}

.ranking-badge .ranking-color-0 .badge-type{color:#FB9650}
.ranking-badge .ranking-color-1 .badge-type{color:#F579A0}
.ranking-badge .ranking-color-2 .badge-type{color:#16C4FB}
.ranking-badge .ranking-color-3 .badge-type{color:#A765DB}

.ranking-badge div[class*="ranking-color-"] .badge-type{color:var(--theme-secondary-dark-bg) !important}


.gcdirectory-detail-page .right-block-title{height: auto;}
.gcdirectory-detail-page .emp-award img{max-width: 100%; min-width: 80px}
.without-shadow .card-body ul li span,
.gcdirectory-detail-page .card-body ul li span{  float: left;    line-height: normal; text-align: left;}
.gcdirectory-detail-page .right-search-block ul li{display: flex; grid-gap:0 20px; margin-bottom: 0px !important; padding-bottom: 20px; border-bottom: 1px solid #eeeeee;}
.gcdirectory-detail-page .right-search-block ul li:last-child{border:0}
.gcdirectory-detail-page .right-search-block ul li a{font-size: var(--font-size-18);}
.gcdirectory-detail-page .right-search-block ul li:hover img{ -webkit-animation: home-arrow-bounceY 2s infinite;        animation: home-arrow-bounceY 2s infinite; }


.without-shadow .card{box-shadow: none !important; padding: 0 !important;}
.myprofile-page .card-left{width:120px}
.myprofile-page .card-mdl{width:calc(100% - 320px) }
.gmyprofile -page .card-right{width:180px}

.common-block{background: #FFFFFF;    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06);    border-radius: 20px; padding:0px 15px; margin-top: 30px;  min-height: 150px;  }
.common-block-title{border-bottom:  1px solid #C4C4C4; margin:0px;}
.common-block-title h3{padding:20px 0 ; margin:0px; color:var(--theme-gray-light-color); font-size: vaR(--font-size-16); font-weight: var(--font-weight-semibold); }


.common-block-content{padding: 20px 0;}
.common-list-block{display: flex; grid-gap:0 70px; }
.common-list{display: flex; flex-direction: column;}
.common-list label{color:var(--theme-base-color); font-size: var(--font-size-13); font-weight: var(--font-weight-regular); line-height: normal;}
.common-list span{color:var(--theme-base-color); font-size: var(--font-size-13); font-weight: var(--font-weight-semibold); line-height: 30px}
.common-list span.font-large{font-size: var(--font-size-28);}
.common-list a{font-size: var(--font-size-13);}

.timeline-list-block{flex-direction: column; grid-gap:15px 0; max-height: 200px; overflow: auto;}
.timeline-list-block .common-list{padding:0 20px; position: relative;}
.timeline-list-block .common-list a{color:var(--theme-secondary-color)}
.timeline-list-block .common-list a:hover,
.timeline-list-block .common-list a:focus{color:var(--theme-base-color) !important}
.timeline-list-block .common-list span{line-height: normal;}
.timeline-list-block .common-list span{line-height: normal;}
.timeline-list-block .common-list:before{width:7px; height: 7px; border-radius: 100%; background: #e5e5e5; content:""; left:0px; top:6px; position: absolute;}
.timeline-list-block .common-list:after{width:1px; height: calc(100% + 20px); border-radius: 100%; background: #e5e5e5; content:""; left:3px; top:6px; position: absolute;}
.timeline-list-block .common-list:last-child:after{display: none;}

.timeline-without-doticon .common-list{padding:0px}
.timeline-without-doticon .common-list:before,
.timeline-without-doticon .common-list:after{display: none;}


.common-block-content .common-paragraph{font-size: var(--font-size-13) ; line-height: 20px; margin-bottom: 10px !important; white-space: pre-wrap;}

.common-badges-list{display: flex; flex-direction: row; grid-gap: 10px;     flex-wrap: wrap;}
.common-gray-badge{background: #eeeeee; color:var(--theme-base-color); font-size: vaR(--font-size-14); height: 40px; display: flex; align-items: center; padding:0 20px; border-radius: 10px;}

.badge-type{font-size: var(--font-size-13) !important; font-weight: var(--font-weight-semibold) !important;  line-height: normal !important;}
.badge-name{font-size: var(--font-size-13) !important; font-weight: var(--font-weight-semibold) !important;  line-height: normal !important; color:var(--theme-secondary-dark-bg) !important}

.accordion-item{box-shadow: none; display: flex; flex-direction: column; margin-bottom: 20px; padding:0 15px; border:1px solid #C4C4C4 !important;  border-radius: 10px !important;}
.accordion-item button{background: none !important; height:60px; padding:0px !important; margin:0px !important;  border-radius: 0px !important; color:#0E3D81 !important; font-size: var(--font-size-16); font-weight: var(--font-weight-medium);}

.accordion-item button.collapsed{color:var(--theme-base-color) !important; }

.accordion-item button::after{background:  url(../../asset/images/icon-minus.png) no-repeat center center!important}


.accordion-item button.collapsed::after{background:  url(../../asset/images/icon-plus.png) no-repeat center center!important}

.accordion-collapse{padding:10px 0}
.accordion-collapse .accordion-body{padding:0px}
.accordion-collapse .accordion-body p.common-paragraph{line-height: 24px; font-size: var(--font-size-16);}


.membership-page .page-title h2{margin-bottom: 15px;}
.selected-plan{margin-bottom: 50px;}
.selected-plan h3{color:var(--theme-base-color); font-size: var(--font-size-16); font-weight: var(--font-weight-regular);}
.selected-plan h3 .change-link{color:var(--theme-secondary-color); padding-left: 5px;}
.selected-plan p .selected-plan-name{color:var(--theme-secondary-dark-bg); font-weight: var(--font-weight-medium); padding:0 5px}
.selected-plan p .selected-plan-price{color:var(--theme-secondary-dark-bg); font-weight: var(--font-weight-medium);padding:0 5px}
.membership-page .btn-primary{width:auto; padding:0 20px}

.inner-title h3{margin:20px 0; font-size: var(--font-size-22); font-weight: var(--font-weight-semibold); color:var(--theme-secondary-dark-bg)}

.cmn-list{display: flex; flex-direction: column; margin:15px 0 0}
.cmn-list label{font-size: var(--font-size-12); font-weight: var(--font-weight-regular); color:var(--theme-base-color)}
.cmn-list span{font-size: var(--font-size-16); font-weight: var(--font-weight-semibold);color:var(--theme-base-color)}

.directory-listing-page .cmn-list label{font-size: var(--font-size-11); font-weight: var(--font-weight-normal);}
.directory-listing-page .cmn-list span{font-size: var(--font-size-13); font-weight: var(--font-weight-medium);}
.directory-listing-page .lawyer-avilibility{min-width: 200px;}


.directory-detail-page .cmn-list label{font-size: var(--font-size-10); font-weight: var(--font-weight-normal);}
.directory-detail-page .cmn-list span{font-size: var(--font-size-12); font-weight: var(--font-weight-medium);}
.directory-detail-page .lawyer-avilibility{min-width: 130px;}


.signup-page .form-row{margin-bottom:20px;}

.our-speaker-title{margin-top:120px !important}
.networking-detail-page .our-speakers ul li{border:0px}
.our-speakers{margin:20px 0 20px !important; display: flex; flex-wrap: wrap;}
.our-speakers .profile-pic{width:80px; height: 80px; overflow: hidden; border-radius: 100%; background: #fafafa; border:1px solid #fafafa}
.our-speakers .profile-pic img{object-fit: cover; width:100%; height: 100%;}

.our-speakers .card{border:0px; padding:0px; grid-gap:10px; height: auto; align-items: center; width: 100%; margin: 0 15px 15px 0;}
.our-speakers .card:nth-child(3n) {margin-right: 0;}
.our-speakers .card .card-header{padding:0; background: none; border:0px}
.our-speakers .card .card-content{display: flex; flex-direction: column; align-items: flex-start; height: auto}
.our-speakers .card .card-content .card-title{font-size: var(--font-size-14); font-weight: var(--font-weight-semibold); color: var(--theme-secondary-dark-bg); margin: 0;
    height: auto; min-height: inherit;}
.our-speakers .card .card-content .common-paragraph{height: auto; min-height: inherit; white-space: pre-line;    text-align: left; margin:0px; padding:0px}

.publication-page .right-search-block .card{padding:0}
.publication-page .right-search-block .card .card-content{padding:10px 30px}
.publication-page .right-search-block .card .card-content a{cursor: pointer;}

.practice-type{color:var(--theme-secondary-color) !important}

.btn-disabled{pointer-events: none; filter: grayscale(100%);}

.employment-detail-page .employment-card{border:0px; margin:20px 0}
.employment-detail-page .employment-card .card-title h2{font-size: var(--font-size-18); font-weight: var(--font-weight-semibold); text-transform: uppercase; color:var(--theme-base-color);}
.employment-detail-page .employment-card .common-paragraph{font-size: var(--font-size-15); line-height: normal; margin: 15px 0 0; color:var(--theme-base-color);}

.employment-detail-page .employment-card ul{margin-left: 20px !important;}
.employment-detail-page .employment-card li{font-size: var(--font-size-15); list-style-type: disc !important; line-height: normal; margin: 10px 0; color:var(--theme-base-color);}
.employment-detail-page .employment-card li:last-child{margin-bottom: 0;}

.employment-detail-page .right-block-title{height: auto;}
.employment-detail-page .right-block-title .card-itm{margin: 0; min-width: inherit;}
.employment-detail-page .right-block-title ul li{padding:10px 0; margin:0px; border-bottom: 1px solid var(--common-border-color);}
.employment-detail-page .right-block-title ul li:last-child{border:0px}
.employment-detail-page .right-search-block .card{padding:10px !important}
.employment-detail-page .right-search-block .card .card-content{padding:0px; margin: 0;}
.employment-detail-page .right-search-block .card-itm .card-footer{padding:0px;     height: auto;  align-items: flex-start;   border-radius: 0;    overflow: visible; flex-direction: column !important;}
.employment-detail-page .right-search-block .card-action{display: block; margin-top: 12px;}
.employment-detail-page .right-search-block .card-title{font-size: var(--font-size-16);}
.employment-detail-page .right-search-block .btn-view{max-width:90px; min-width: 90px; height: 26px; border-radius:6px !important; font-size: var(--font-size-10); grid-gap: 5px;}
.employment-detail-page .right-search-block .btn-view:hover{color:#fff !important}
.employment-detail-page .right-search-block .btn-view svg{width:9px !important}



.custom-date .react-date-picker{width:100%}
.custom-date .react-date-picker__wrapper{height: var(--common-input-height);    border: 1px solid var(--common-input-border);    border-radius: 10px; width: 100%;}
.custom-date button svg{opacity: 0.5;}
.custom-date input{    height: var(--common-input-height);   border: 1px solid var(--common-input-border) !important;    border-radius: var(--common-border-radius) !important; width:100%; padding:0 10px; border:0; border-radius: 0;}

.after-login-page .extra-info-rgt .btn-whatsup{display: none;}
.after-login-page .extra-info-rgt .lawyer-avilibility{/*display: none;*/}
.after-login-page .extra-info-rgt .lawyer-avilibility .cmn-list{text-align: right;}
.after-login-page .btn-upgrade-ranking{/*display:none;*/}


.after-login-page .page-title h2{font-size: var(--font-size-26); margin: 0;}

.profile-statistics{display: flex; flex-direction: row; grid-gap:10px; padding:10px; border-radius:20px; background: #fafafa; height: 80px; align-items: center; }
.profile-stats{padding:0 15px; display: flex; flex-direction: column; border-right:1px solid var(--common-border-color)}
.profile-stats:last-child{border:none}
.profile-stats span{font-size: var(--font-size-22); font-weight: var(--font-weight-semibold); color:var(--theme-secondary-color)}
.profile-stats label{font-size: var(--font-size-12); color:var(--theme-base-color)}

.after-login-page.publication-page .right-search-block .card .card-content{padding:0px}
.after-login-page .publication-content{padding: 0;}

.custom-btn-link{float:right; height:36px; width:auto; display: inline-flex; font-size: var(--font-size-12); padding:0 10px; grid-gap:5px; min-width: inherit !important;}
.custom-btn-link img,
.custom-btn-link svg{width:11px}

.dropdown-menu[data-bs-popper]{left:inherit !important; right:0;     margin-top: 5px !important;  overflow: hidden;}
.dropdown-item:hover{background: var(--theme-base-color) !important; color:var(--color-white) !important}

.add-icon{background: url(../../asset/svg/icon-plus.svg) no-repeat center center; width:20px; height: 20px;}
.email-icon{background: url(../../asset/images/social/icon-mail.png) no-repeat center center; width:30px; height: 30px; }
.social-links button:hover .email-icon{background: url(../../asset/images/social/icon-mail-hvr.png) no-repeat center center; width:30px; height: 30px; }
.social-fb-icon{background: url(../../asset/images/social/icon-facebook.png) no-repeat center center; width:30px; height: 30px; }
.social-tt-icon{background: url(../../asset/images/social/icon-twitter.png) no-repeat center center; width:30px; height: 30px; }
.social-link-icon{background: url(../../asset/images/social/icon-linkedin.png) no-repeat center center; width:30px; height: 30px; }
.social-yt-icon{background: url(../../asset/images/social/icon-youtube.png) no-repeat center center; width:30px; height: 30px; }
.social-wapp-icon{background: url(../../asset/images/social/icon-whatsapp.png) no-repeat center center; width:30px; height: 30px; }

.social-links a:hover .social-fb-icon{background: url(../../asset/images/social/icon-facebook-hvr.png) no-repeat center center; width:30px; height: 30px; }
.social-links a:hover .social-tt-icon{background: url(../../asset/images/social/icon-twitter-hvr.png) no-repeat center center; width:30px; height: 30px; }
.social-links a:hover .social-link-icon{background: url(../../asset/images/social/icon-linkedin-hvr.png) no-repeat center center; width:30px; height: 30px; }
.social-links a:hover .social-yt-icon{background: url(../../asset/images/social/icon-youtube-hvr.png) no-repeat center center; width:30px; height: 30px; }
.social-links a:hover .social-wapp-icon{background: url(../../asset/images/social/icon-whatsapp-hvr.png) no-repeat center center; width:30px; height: 30px; }


.photos-page .other-photo{width:100%; height: 100%;   height: 200px ;     overflow: hidden; position: relative; margin-bottom: 30px;} 
.photos-page .other-photo .img-wrap{width:100%; height: 100%; /*filter: drop-shadow(0px 12px 25px rgba(0, 0, 0, 0.06));*/ border:1px solid #ededed; background: #fff;  border-radius: 20px; position: relative;}
.photos-page .other-photo .photo-upload-img { padding:0px;   width: 100%;    height: 100%;    object-fit: cover; overflow: hidden;}

.other-photo i{color:#444; font-size: 18px;}

.photos-page .other-photo [data-rmiz-wrap=hidden], 
.photos-page .other-photo [data-rmiz-wrap=visible]{height:200px}

.photos-page .other-photo .img-wrap button{position: absolute; left:0; right:0; width:100%; height:100%; background: none;}

.other-photo button{ position: absolute;  z-index: 9999;  width: 45px; height: 45px;   background: rgba(255,255,255,0.5);
    border: 0;    font-size: 25px;    line-height: 30px;    border-radius: 0 0 0 20px; right:-45px; top:-45px; transition: all 0.3s ease;}
.other-photo:hover button{    right: 0;  top: 0;   }

.custom-itm button{ position: absolute;  z-index: 9;  width: 45px; height: 45px;   background: rgba(255,255,255,0.7);    border: 0;    font-size: 25px;    line-height: 30px;    border-radius: 0 0 0 20px; right:-45px; top:-45px; transition: all 0.3s ease;}
.custom-itm:hover button{    right: 0;  top: 0;   }


.video-page .custom-itm{width:100%; height: 100%; border-radius: 20px; position: relative; overflow: hidden;}
.video-page .custom-itm iframe{border-radius: 20px; width:100%; height: 100%;}

.employment-page .right-block-title{height: auto;}

.custom-table{margin: 50px 0;}
.custom-table .react-bootstrap-table table{border:0px !important; box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06);}
.custom-table .react-bootstrap-table table thead{border:0px !important}
.custom-table .react-bootstrap-table table tr{border:none !important}
.custom-table .react-bootstrap-table table thead th{background: var(--theme-base-color); color:var(--color-white); border:0 !important; box-shadow: none; height: 50px; padding:0 20px !important}
.custom-table .react-bootstrap-table table thead th:first-child{border-radius:20px 0 0 0}
.custom-table .react-bootstrap-table table thead th:last-child{border-radius:0 20px 0 0}
.custom-table .react-bootstrap-table table tbody{border:none !important}
.custom-table .react-bootstrap-table table tbody td{ border:0 !important; box-shadow: none; border-bottom:1px solid var(--common-border-color) !important}

.custom-table .tableTopBlock{display: none;}
.custom-table .react-bootstrap-table{height: auto;}
.custom-table.without-pagging .react-bootstrap-table-pagination{display: none;}

.ranking-page .common-paragraph{font-size: var(--font-size-20);}
.ranking-page .page-title  .common-paragraph{font-size: var(--font-size-16); margin: 30px auto 50px; max-width: 65%;}


.custom-table-with-thead table{border:0px !important; box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06);}
.custom-table-with-thead table thead{border:0px !important}
.custom-table-with-thead table tr{border:none !important}
.custom-table-with-thead table thead th{background: var(--theme-base-color); font-size: var(--font-size-14); color:var(--color-white); border:0 !important; box-shadow: none; height: 50px; padding:0 20px !important; vertical-align: middle; font-weight: normal;}
.custom-table-with-thead table thead th:first-child{border-radius:20px 0 0 0}
.custom-table-with-thead table thead th:last-child{border-radius:0 20px 0 0}
.custom-table-with-thead table tbody{border:none !important}
.custom-table-with-thead table tbody td{ border:0 !important; box-shadow: none;}
.custom-table-with-thead table thead th:nth-child(1),
.custom-table-with-thead table tbody td:nth-child(1){text-align: left !important; padding:0 15px}

.custom-table-with-thead table thead th:last-child,
.custom-table-with-thead table tbody td:last-child{width:120px; max-width: 120px;}

.custom-listview-action .btn-delete:before{font-size: var(--font-size-18);}
.custom-listview-action .btn-delete:hover:before{color:#820024 !important}

.add-publications .additional-form-info{margin-top: -45px;}
.add-publications .additional-form-info .custom-tooltip{display: none;}
.add-publications .additional-form-info .btn-primary{height: 30px; min-width: inherit; width:auto; padding:0 10px}

.add-publications .no-record-block{box-shadow: none; height:200px}
.add-publications .no-record-block img{width:100px; height: auto;}
.add-publications .no-record-block h3{font-size: var(--font-size-16); width:200px}

.gcsignup-page .form-row{margin-bottom: 20px; display: flex; flex-direction: column;}
.gcsignup-page .profile-action-area .btn-primary{width:160px; height: 50px; min-width: inherit; padding:0 15px; background: var(--secondary-green-color); margin-top: 50px !important;}

.settings-content  .event-lst-box{grid-gap:0; margin-top: 30px;}
.settings-content .card-title{min-height: inherit; margin:0px; display: flex; align-items: center; font-size: var(--font-size-16); font-weight: var(--font-weight-medium); color:var(--theme-base-color)}
.settings-content .card-action{display: flex;}
.settings-content .card-itm{margin:0px 0}
.settings-content .card-itm .card-content{padding-bottom: 20px;}
.switch-btn {display: flex;}
.switch-btn .switch-input-btn[type=checkbox]{height: 0;	width: 0;	visibility: hidden;}
.switch-btn .switch-input-label {cursor: pointer; text-indent: -9999px; width: 70px; height: 36px; background: grey; display: block;	border-radius: 100px;	position: relative;}
.switch-btn .switch-input-label:after {	content: '';	position: absolute;	top: 3px;	left: 4px;	width: 30px;	height: 30px;	background: #fff;	border-radius: 90px;	transition: 0.3s;}
.switch-btn .switch-input-btn:checked + .switch-input-label {	background: var(--secondary-green-color);}
.switch-btn .switch-input-btn:checked + .switch-input-label:after {left: calc(100% - 5px);	transform: translateX(-100%);}
.switch-btn .switch-input-btn .switch-input-label:active:after {	width: 130px;}

.apl-badge{display: flex; align-items: center; text-align: center; border-radius: var(--common-border-radius); text-align: center; place-content: center;}
.apl-badge-paid{background: var(--secondary-green-color); height: 36px; min-width: 90px; padding:0 10px; font-size: var(--font-size-12); color:var(--color-white)}
.apl-badge-unpaid{background: var(--red-color); height: 30px; min-width: 90px; padding:0 10px; font-size: var(--font-size-12); color:var(--color-white)}


.common-page-content .tab_container .tab_content .inner-page{margin-top: 0px !important;}
.common-page-content .tab_container .tab_content .inner-page:before{display: none;}
.common-page-content .tab_container .tab_content .inner-page .col-lg-9{width:100%}
.common-page-content .tab_container .tab_content .inner-page .col-lg-3{display: none;}
.common-page-content .tab_container .tab_content .inner-page .col-lg-3.show-prefered{display: block;}

.common-page-content.publication-content .tab_container .tab_content .inner-page .col-lg-9{width:75%}
.common-page-content.publication-content .tab_container .tab_content .inner-page .col-lg-3{display: block;}

.common-page-content.publication-content .tab_container .tab_content .inner-page.editprofile-page .col-lg-9{width:100%}
.common-page-content.publication-content .tab_container .tab_content .inner-page.editprofile-page .col-lg-3{display: none;}

.networking-detail-page .right-search-block ul li:hover button, 
.networking-detail-page .right-search-block ul li:hover a{color:#fff !important}

.publication-page .right-search-block ul li button, 
.publication-page .right-search-block ul li a{font-size: var(--font-size-15) !important; font-weight: var(--font-weight-regular);}

.common-page-content.networking-content .time-stats span{display: none;}

.signup-form-block .form-row{margin-bottom: 30px;}

.custom-table-with-thead .no-record-block{height: 160px; box-shadow: none;}
.custom-table-with-thead .no-record-block img{width:80px; height: auto;}
.custom-table-with-thead .no-record-block h3{font-size: var(--font-size-16); max-width: 190px;}

.signup-form-table{margin-bottom: 50px;}
.signup-form-table .formview-child-top-title{margin-top: -50px;}
.signup-form-table .custom-tooltip{display: none;}

.signup-form-table .custom-table-with-thead tabletable {
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    border:1px solid #eee !important
  }

.signup-form-table .custom-table-with-thead table thead th{background: #fafafa; color:var(--theme-base-color); border:0px solid #eee !important}
.signup-form-table .custom-table-with-thead table tbody td{height:50px !important; vertical-align: middle; border-top:1px solid #eee !important}


.upload-btn{position: absolute; right:10px; bottom:10px; border:0px; background: none;}
.upload-icon{ background:url(../../asset/svg/icon-upload.png) no-repeat center bottom; width:26px; height: 26px; display: block;}

.btn-edit .edit-icon{ background:url(../../asset/svg/icon-edit.svg) no-repeat center bottom; width:16px; height: 16px;  display: block;}
.btn-edit:hover .edit-icon{background:url(../../asset/svg/icon-edit-hover.svg) no-repeat center bottom;}

.btn-back .back-icon{ background:url(../../asset/svg/icon-arrow-left.svg) no-repeat center bottom; width:12px; height: 12px;  display: flex;}
.btn-back:hover .back-icon{background:url(../../asset/svg/icon-arrow-left-hover.svg) no-repeat center bottom;}

.afterlogin-inner-head{height: 120px;}

.common-page-content .tab_container .tab_content .inner-page.common-inner-profile-page{margin:0px 0 !important; padding:0px}


.changepasword-page .form-row{margin-bottom: 30px !important;}

.editprofile-page .img-wrap{width:170px; height: 170px; border-radius: 100%; overflow: hidden;}
.profile-img {width:170px; position: relative;}
.profile-img .custom-btn-group{position: absolute; right:0; bottom: 0; }
.profile-img .custom-btn-group .custom-btn{width:50px; height: 50px; display: flex; align-items: center; place-content: center; background: var(--secondary-green-color); border:0; border-radius: 100%;}
.profile-img .upload-icon{width:19px; height: 19px;}


.custom-textarea{height: 120px !important; resize: none;}
.add-job .form-section,
.add-publications .form-section{  margin: 20px 0; /*height: 480px; overflow-y: auto; overflow-x: hidden; */ padding: 0 30px;}

.swal-modal{background:#fff url(../../asset/images/modal-bg.png) no-repeat center top; border-radius: 20px;}
.swal-icon{width:90px; height: 90px; border:20px solid #fff !important;}
.swal-icon--warning{background: #f8bb86;}
.swal-icon--warning__body,
.swal-icon--warning__dot{background: #fff;}
.swal-title{font-size: var(--font-size-40); font-weight: var(--font-weight-bold);}
.swal-text{font-size: var(--font-size-20); font-weight: var(--font-weight-medium); text-align: center; max-width: 70%;}
.swal-footer{text-align: center;}

.swal-icon--success__ring{background: #a5dc86; width:90px; height: 90px;}
.swal-icon--success__line{background-color:#fff; z-index: 99;}
.swal-icon--success:after,
.swal-icon--success:before{display: none;}
.swal-icon--success__line--long{    width: 54px; right: 10px;    top: 45px;}
.swal-icon--success__line--tip{top:53px}


.contact-link{font-weight: var(--font-weight-medium) !important;   justify-content: flex-start !important;  min-width: 180px !important;    padding: 0 10px !important;   grid-gap: 7px !important;}

.header-fixed .right-search-block{position: sticky; top:120px}
.publication-page .right-search-block .card .card-content{height: 500px;}
/*.header-fixed  .publication-page .right-search-block .card .card-content{height: 500px;}*/

.editrofile-tab-content .common-page-content{display: none;}
.lazyload-wrapper{width:100%}
.networking-content .lazyload-wrapper{width:25%}

.editprofile-page .col-md-9,
.editprofile-page .col-lg-9{width:100%}
.editprofile-page .signup-form-block{max-width: 75%; margin: auto;}
.editrofile-tab-content .editprofile-page .signup-form-block{max-width: 100%; margin:40px auto auto;}

.custom-btn-group .btn-primary{height: 30px; min-width: inherit; width:auto; padding:0 10px}

.btn-email{background:#d5493d !important}


.badge-cancel{background: var(--red-color); color:#fff;     padding: 0 10px;   height: 36px; display: flex; align-items: center; border-radius: var(--common-border-radius);}
.btn-cancel{background: #fff !important; border:1px solid var(--red-color); color:var(--red-color); text-transform: capitalize; grid-gap:5px}
.btn-cancel:hover{border-color: var(--theme-base-color);}
.btn-cancel i{font-size: 16px;}

.btn-view.btn-edit{grid-gap: 5px;}
.btn-view.btn-edit i{font-size: 15px;}

.bookings-page .custom-table{margin: 0;}
.booking-table.custom-table{margin-top: 0; margin-bottom: 20px;}
.react-bootstrap-table-pagination-list .page-item.active .page-link{color: #fff !important;}
.upgrade-ranking-badges{margin-bottom: 100px;}
.upgrade-ranking-badges h4{display: none;}
.upgrade-ranking-badges .active-rank h4{display: flex; flex-direction: column; margin-top: 20px; font-weight:var(--font-weight-semibold)}
.upgrade-ranking-badges .active-rank h4 span{color:var(--theme-base-color); font-weight:var(--font-weight-normal)}
.upgrade-ranking-badges img{filter:grayscale(100%); opacity: 0.5;  max-width: 156px;}
.upgrade-ranking-badges .active-rank img{filter:grayscale(0%); opacity: 1;max-width: 200px;}
.upgrade-ranking-badges ul{display: flex; align-items: baseline; justify-content: space-between;}
.upgrade-ranking-badges ul li{text-align: center;}


.upgrade-ranking-page .custom-action .btn-green{min-width: 120px;}

.upgrade-ranking-page .custom-dropdown{max-width: 400px;}


.after-login-page .extra-info{position: relative; width:calc(100% - 130px); margin: 0;}
.extra-info-lft{/*width:calc(100% - 390px);*/}
.extra-info-rgt{position: absolute; right:0;     display: flex;    flex-direction: column;}
.after-login-page .common-page-content .card-itm.emp-card-itm .card-action{/*position: absolute; right:0*/}

.badge-pending{color:#ffa500 !important; margin-left: 2px;}
.badge-approved{color:#64c093 !important; margin-left: 2px}
.badge-rejected{color:#fa6262 !important; margin-left: 2px} 

.event-lst-box.no-ranking-badge{position: relative;}
.event-lst-box.no-ranking-badge .extra-info{position: static;}
.event-lst-box.no-ranking-badge .extra-info-rgt{top:0; right:5px; z-index: 999;}


.without-shadow .emp-award .emp_ranking{margin:0 8px}
.without-shadow .extra-info-lft .emp-comp-detail .emp_ranking{margin:0;  }

.directory-detail-page .without-shadow .extra-info-lft .emp-comp-detail .emp-award{align-items: center; height: auto; width:auto; max-width:inherit;}
.directory-detail-page .without-shadow .extra-info-lft .emp-comp-detail .emp_ranking{margin:0;  min-width:80px; max-width: 200px;    height: 80px;}
.directory-detail-page .without-shadow .extra-info-lft .emp-comp-detail .emp_ranking img{min-width: inherit; max-width: inherit; width:100%; height: 100%; object-fit:cover; max-height: inherit;}



.ReactModal__Content form{min-width: 400px;}

.inner-header-rgt  .search-field{margin-left: 30px !important; margin-right:5px !important}

.publication-posted-by{margin:20px 0 30px;}
.publication-posted-by .posted-pic{width:80px; height: 80px; overflow: hidden; border-radius: 100%; margin-right:10px; border:1px solid var(--common-border-color)}
.publication-posted-by .posted-pic img{object-fit: cover; width: 100%; height: 100%;}
.publication-posted-by .publication-by{margin:0px 0 2px; padding: 0px; font-size: var(--font-size-14); font-weight: var(--font-weight-medium); color:var(--theme-secondary-dark-bg)}

.publication-posted-by.org-logo .posted-pic{width:auto !important; border-radius: 0; border:0; height: auto; max-height: 70px;}

.top-btn{width: 50px; height: 40px;  border: 0;    border-radius:25px 25px 0 0;       float: right;  margin-top: -40px;  margin-right: 10px;  font-size: 14px;   line-height: 17px;
        font-weight: 500;    background: var(--theme-secondary-color); color: #fff; position: fixed; bottom:-40px; right: 0; z-index: 9; transition: all 0.3s ease;}
.header-fixed .top-btn{bottom:0px}

.additional-form-info .no-record-block h3{display: none;}

.custom-radio{display: flex; }
.custom-radio .input-group{margin-right:15px; max-width: 60px; align-items: center; display: flex; grid-gap:5px; font-size: var(--font-size-16);}

.publication-reference-list h4{font-size: var(--font-size-18); font-weight: var(--font-weight-semibold); color:var(--theme-base-color); margin: 0 0 10px;}
.publication-reference-list ul{display: list-item; list-style-type: disc; margin-left: 20px !important;}
.publication-reference-list ul li{display: list-item; list-style-type: disc; line-height: 22px; color:var(--theme-secondary-color); font-size: var(--font-size-16);}

.event-booked{background:#146ce2 !important; color:#fff; pointer-events: none;}

.filter-toggler{display: none;}
.nav-tabs .nav-link{cursor: pointer !important;}

.ranking-banner{position: relative;}
.ranking-banner p{position: absolute;   bottom: 105px; font-size: 30px !important; line-height: 30px;    left: 0;  right: 0;  margin: auto;  max-width: 220px;  white-space: pre-line;  text-transform: uppercase;    color: #fff;}

.directory-ranking .emp_ranking{position: relative; margin:0px}
.directory-ranking .emp_ranking img{min-width: 90px; max-width: 90px; max-height: inherit; /*width:100%; height: 100%; object-fit: cover;*/}
.directory-ranking .emp_ranking h4{position: absolute;  top: 64px; left:0; right:0; margin:auto;  color: #fff;   text-transform: uppercase;    max-width: 70px; font-size: 8px;  display: -webkit-box;
    -webkit-line-clamp:2;    -webkit-box-orient: vertical;      overflow: hidden;}

[data-rmiz-overlay]{display: flex; transition-duration: 100ms !important;}
[data-rmiz-overlay] > div{top: 0 !important; right:0; transform: none !important; left:0 !important; margin: auto; bottom:0; width:309px !important; height: 383px !important; text-align: center;}
/*div[role="dialog"] img{width:309px !important; height: 383px !important;}*/
[data-rmiz-overlay] > div h4{position: absolute;   bottom: 105px;    left: 0;    right: 0;    max-width: 80%;    color: #fff;    font-size: 28px;    margin: auto;    line-height: 32px;  display: -webkit-box;
    -webkit-line-clamp:2;    -webkit-box-orient: vertical;      overflow: hidden;}

    .mobile-desc{display: none;}


    .upgrade-ranking-badges .emp_ranking{position: relative;}
    .upgrade-ranking-badges .emp_ranking h3{position: absolute; max-width:170px; bottom: 65px; left: 0; right: 0; margin: auto; color:#fff; font-size: var(--font-size-18); 
        text-transform: uppercase; display: -webkit-box;
        -webkit-line-clamp:2;    -webkit-box-orient: vertical;      overflow: hidden;}
    .upgrade-ranking-badges .no-rank .emp_ranking h3{ bottom: 52px;  font-size: var(--font-size-14);  max-width: 110px; height: 35px;        display: flex;        align-items: center;  display: -webkit-box;
        -webkit-line-clamp:2;    -webkit-box-orient: vertical;      overflow: hidden;}

    .ranking-review{height: 60px; margin-bottom: 30px; border-radius: 30px; width:100%; text-align: center; background: #fff7d7; font-size: var(--font-size-26); display: flex; align-items: center; place-content: center; grid-gap: 15px;}
    .ranking-review span{color:#816b14; font-weight: var(--font-weight-semibold);}

    .upgrade-ranking-page .form-row label.custom-file-upload{width:100% !important}
    .upgrade-ranking-page .custom-file-container__image-preview{display: none;}

    .directory-detail-page .tab-pane .afterlogin-inner-head{height: auto; min-height: 15px; margin-bottom: 15px; margin-top: 15px;}

    .publication-reference-list ul:empty {     display: none;   }

    .right-search-block .no-record-block{box-shadow: none;}
    .right-search-block .no-record-block h3{max-width: 90%;}

 .send-email-block{padding:40px 30px 0; min-width: 500px; overflow:hidden;}
.send-email-block .form-row{margin-bottom: 20px !important;}

.secondary-page-title h2{display: block !important;}

.document-list ul{display: flex; flex-wrap: wrap; margin-top: 30px !important;}
.document-list ul li{width:33.33%; padding:10px; display: flex; align-items: center; grid-gap: 10px; position: relative; border:1px dashed #e1e1e1; border-radius: 10px; margin-right:10px}
.document-list ul li label{width: 190px; white-space: pre-line; font-size: 13px; text-overflow: ellipsis; overflow: hidden}

.document-list ul li .close-link{background-color: #999; width:20px; height: 20px; line-height: 20px; border-radius: 100%; text-align: center; color:#fff;     position: absolute;
    right: 10px;    top: 10px;}
.document-list ul li .close-link:hover{background-color: #0e3d81; color:#fff !important}

main .inner-page.common-page-content {min-height: 400px;}
.common-content-page span,
.common-content-page p{}
.common-content-page ul li,
.common-content-page ol li{list-style: inherit; }

.download-link{background:var(--theme-secondary-color) !important;}
.download-link i{font-size: var(--font-size-18);}

.profile-tab-content .no-record-block img{max-width: 50px; height: auto;}
.profile-tab-content .no-record-block{height:auto; margin:15px 0px; border-radius: 0; box-shadow: none; grid-gap:10px}
.profile-tab-content .no-record-block h3{width:100%; font-size: var(--font-size-14);}


.common-block .no-record-block img{max-width: 50px; height: auto;}
.common-block .no-record-block{height:auto; margin:15px 0px; border-radius: 0; box-shadow: none; grid-gap:10px}
.common-block .no-record-block h3{width:100%; font-size: var(--font-size-14);}

.custom-date input.react-date-picker__inputGroup__input{border-radius: 0 !important; border:none !important}

.mobile-view{display: none !important;}

.reset-btn{width:100%; border:0px; background:none; font-size: var(--font-size-13); text-align: right; color:var(--red-color)}
.reset-btn:hover{color:var(--theme-secondary-color)}

.our-speakers .splide__track{padding-left:0 !important}

.resetpassword-page.inner-page{margin:-75px 0 0; min-height: inherit; height: 100vh; overflow: hidden;}
.resetpassword-page .form{max-width: 700px; margin: auto;     height: 100vh;    display: flex;    align-items: flex-start;    flex-direction: column;    place-content: center;}
.resetpassword-page.inner-page:before{background: url(../../asset/images/background/resetpassword-bg.png) no-repeat center top; background-size: 100vw 100vh; content:""; width:100vw; height:100vh; position: absolute; left:0; top:0px; pointer-events: none;}

.react-datepicker-popper[data-placement^=bottom]{width:100% !important}
.react-datepicker__year-wrapper{max-width: 100% !important;}
.react-datepicker__header{background:var(--theme-secondary-color) !important; color:var(--color-white) !important}

.react-datepicker__navigation-icon{top:4px}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{background:var(--theme-secondary-color) !important;}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{border-color:#fff !important}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{border-bottom-color:var(--theme-secondary-color) ;}

.ReactCrop{width:450px; height: 450px;}
.crop-container{display: flex; flex-direction: column; grid-gap: 15px; padding:15px; text-align: center;}
.crop-preview{display: none;}

.pricing-page p{max-width: 50%; margin:20px auto;}

.error-404-page.inner-page{margin:-75px 0 0; min-height: inherit; height: 100vh; overflow: hidden;}
.error-404-page .form{max-width: 700px; margin: auto;     height: 100vh;    display: flex;    align-items: flex-start;    flex-direction: column;    place-content: center;}
.error-404-page.inner-page:before{background: url(../../asset/images/background/resetpassword-bg.png) no-repeat center top; background-size: 100vw 100vh; content:""; width:100vw; height:100vh; position: absolute; left:0; top:0px; pointer-events: none;}

.error-content-page{display: flex; flex-direction: column; grid-gap: 20px;}
.error-content-page .search-action{margin:auto}
.error-content-page .search-action button{padding:0 15px !important; width:auto !important}

.common-block-merge{display: flex; flex-wrap: wrap; grid-gap: 20px; ; padding:20px; margin-top: 10px;}
.common-block-merge .common-block-lft{width:calc(65% - 10px); border-right:1px solid var(--common-border-color); padding:0 15px}
.common-block-merge .common-block-rgt{width:calc(35% - 10px);}
.common-block-merge .common-block-title h3{padding: 10px 0;}
.common-block-merge .common-block-title{border-color:var(--common-border-color) ;}

.page-item.disabled .page-link{  width: 35px;   height: 35px;    text-align: center;    line-height: 35px; padding: 0;}
.page-item.active .page-link{  width: 35px;   height: 35px;    text-align: center;    line-height: 35px; padding: 0; background:var(--theme-base-color) ; border-color: var(--theme-base-color); font-size: var(--font-size-14);}

.common-content-page{white-space: pre-wrap;}
.common-content-page ul,
.common-content-page ol{margin-left: 30px !important;}

.detail-content-top .our-speakers{ flex-direction: column;}
.detail-content-top .our-speakers h3{font-size: var(--font-size-14); color:var(--theme-base-color); margin-bottom: 20px; font-weight: var(--font-weight-semibold);}
.detail-content-top .our-speakers .card{width:31.8%}
.detail-content-top .our-speakers .card .card-content .card-title{color:var(--theme-secondary-dark-bg); font-size: var(--font-size-14); font-weight: var(--font-weight-semibold);}
.detail-content-top .our-speakers .profile-pic{width:40px; height: 40px;}
.ranking-color .icon-badge img{opacity: 0.5;}
.ranking-color .badge-name{color:rgba(0,0,0,0.9) !important; font-weight: var(--font-weight-normal) !important;}

.card-body ul li.court-practise span,
.card-body ul li.court-practise span i{color:rgba(0,0,0,0.8) !important;}

.card-body ul li.practicing-courts span,
.card-body ul li.practicing-courts span i{color:rgba(0,0,0,0.8) !important;}

.card-body ul li.expertise-list span{color:var(--theme-secondary-color)}
.react-viewer {z-index:99999 !important}
.fixed-top{z-index: 9;}
.badge-publication-inactive{background: var(--red-color); color:#fff; cursor: pointer; margin-left: 2px;    padding: 0 10px;  align-items: center; border-radius: var(--common-border-radius);}
.badge-publication-active{background: var( --secondary-green-color); color:#fff; margin-left: 2px;    padding: 0 10px;  align-items: center; border-radius: var(--common-border-radius);}
.cursor-on-bage{cursor: pointer;}
.upload-photo-label label{ font-size: var(--common-label-size); color: var(--common-label-color);}
.input-container input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
.detail-content-top .our-speakers.event-our-speakers{margin-top:10px !important}

span b.unlimited_symbole {font-size: 34px; line-height: 0px;}

.custom-downlod-invoice{color:blue !important; cursor: pointer;}
.custom-downlod-invoice:hover{color:blue!important; text-decoration: underline;}



.privacy-policy-page ol{margin-top:0px !important; margin-bottom:0px !important; float:left; width:100%}
.privacy-policy-page ol li{margin: 0 0 12px !important;     font-size: var(--font-size-15) !important;
    color: var(--common-text-color);    font-weight: var(--font-weight-normal);    margin: auto;    line-height: 24px;    white-space: pre-wrap;}
.privacy-policy-page p{float:left; width:100%; margin:0 0 20px !important;     line-height: 33px;}

.privacy-policy-page h2{margin:0px !important; padding:0px !important; float:left; width:100% !important; font-size: var(--font-size-15) !important;
    color: var(--theme-secondary-dark-bg)!important;    font-weight: var(--font-weight-semibold) !important;    line-height: 24px;}
    .privacy-policy-page b{color: var(--theme-secondary-dark-bg)!important;}

.termsconditions ol{margin-top:0px !important; margin-bottom:30px !important; float:left; width:100%}
.termsconditions ol li{margin: 0 0 10px !important}
.termsconditions p{float:left; width:100%; margin-bottom:20px !important;     line-height: 33px;}
.brd-lft { border-left: 2px solid var(--common-input-border); padding-left: 20px;}
.brd-rgt { padding-right: 20px;}
.contact-communication ul { list-style: none; margin-left: 0px !important;}
.brd-btm { border-bottom: 2px solid var(--common-input-border); }
.contact_company_name strong {font-weight: var(--font-weight-bold); color: var(--theme-secondary-dark-bg) !important; font-size: 22px;}
.address-map-img img {width: 100%;}
.addess-txt {font-size: 16px;}
.contact-page .custom-textarea {height: 85px!important;}

.sitemap-rw-two ul li {
    margin-bottom: 10px;
    font-size: 16px;
    width: 30%;
    margin-right: 2%;
    float: left;
    list-style: none;
    padding-left: 20px;
    position: relative;
}
.sitemap-rw-two ul li a {
    color: var(--common-text-color);
    font-weight: 400;
    font-size: 16px;
    /* color: #666; */
    line-height: 28px;
    padding: 10px 10px 10px 25px;
    list-style-type: disc;
    border: 1px solid transparent;
    box-shadow: 0 6px 23px transparent;
    white-space: nowrap;
}

.sub-hd {

    font-size: 20px;
    color: #000;
    line-height: 45px;
    font-weight: 600;
    margin: 30px 0;
    position: relative;
    border-bottom: 1px dotted #9a9a9a;
}
.sub-hd a {
    color: #202020;
    font-size: 20px;
    text-decoration: none;
}

.sitemap-rw-two ul{margin:0px;  padding: 0px;}
.sitemap-rw-two ul li{margin:3px 0px;}
.sitemap-rw-two ul li a{    font-family: var(--font-family-regular);
    font-weight: 400;
    font-size: 16px;
    color: rgb(77, 77, 77);
    line-height: 28px;
    padding:10px !important;
    list-style-type: disc;
    border: 1px solid transparent;
    box-shadow: 0 6px 23px transparent;
    white-space: nowrap;}
    .sitemap-rw-two ul li a:before{    content: "•";
        position: absolute;
        top: 0px;
        left: 10px;
        color: rgb(99, 99, 99);
        font-size: 16px;
        margin-right: 5px;}
        .sitemap-rw-two ul li a:hover{color:var(--theme-secondary-color) !important}
        main .inner-page.Sitemap { min-height: 650px;}
.banner-section .search-block .form-group .form-row.lawyer_input_wrap {width:calc(100% - 15px); }

div.show_city_state_name {font-weight: bold;}
div.small_state_name {font-size:12px; font-weight: normal; margin-top: -5px;}
div.small_state_name small {color: #444;}
.rbt-menu.dropdown-menu a:hover div.small_state_name small{color: #fff7d7;}

.rbt-menu.dropdown-menu a.active[aria-selected="true"] {
    background: var(--theme-secondary-dark-bg) !important;
    color: var(--color-white) !important;
}

.rbt-menu.dropdown-menu a.active[aria-selected="true"] div.small_state_name small{color: #fff7d7;}
.right-search-block .search-block div.city_state_id .dropdown-menu {min-width:calc(14% - 14px);}