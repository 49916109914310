.loader-container {
  height: 100vh;
  width: 100vw;
}

.loader {
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  background-color: #8cc759;
  animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #8c6daf;
  animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #ef5d74;
  animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #f9a74b;
  animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #60beeb;
  animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #fbef5a;
  animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 30rem;
  margin: auto;
}
.loader--text:after {
  content: "Please read good quotes until we load the data.. ";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "“Without great solitude, no serious work is possible.” – Pablo Picasso";
  }
  10% {
    content: "“To lose patience is to lose the battle.” — Mahatma Gandhi";
  }
  20% {
    content: "Nothing is impossible: The word itself says “I’m possible!” — Audrey Hepburn";
  }
  30% {
    content: "“Learn from the mistakes of others… you can’t live long enough to make them all yourselves” — Chanakya";
  }
  40% {
    content: "“No one can defeat a powerful mind.” — Chanakya";
  }
  50% {
    content: "“A man is great by deeds, not by birth.” — Chanakya";
  }
  60% {
    content: "“Humbleness is at the root of self control.” — Chanakya";
  }
  70% {
    content: "“As soon as the fear approaches near, attack and destroy it..” — Chanakya";
  }
  80% {
    content: "“God is not present in idols. Your feelings are your God. The soul is your temple.” — Chanakya";
  }
  90% {
    content: "“Never settle for anything less than what you deserve. It’s not pride, it’s self respect.” — Chanakya";
  }
}