.custom-file-container__image-preview {
  box-sizing: border-box;
  transition: all 0.2s ease;
  margin: 20px 0 0;
  padding:0px;
  height: auto;
  width: 100%;
  border-radius: 4px;
  overflow: scroll;
  border:0px;
  background: none;
  box-shadow: none;
}
.custom-file-container__image-multi-preview {
  position: relative;
  box-sizing: border-box;
  transition: all 0.2s ease;
  border-radius: 6px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  float: left;
  margin: 1.858736059%;
  width: 55px;
  height: 70px;
  box-shadow: 0 4px 10px 0 rgb(51 51 51 / 25%);
}

.custom-file-container__image-multi-preview__single-image-clear:hover {
  background: #cbcbbd;
  cursor: pointer;
}
.custom-file-container__image-multi-preview__single-image-clear {
  right: -6px;
  background: #ffbc00;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  margin-top: -6px;
  box-shadow: 0 4px 10px 0 rgb(51 51 51 / 25%);
}
.custom-file-container__image-multi-preview__single-image-clear__icon {
  color: #ff0000;
  display: block;
  margin-top: -2px;
}


.filelabel {
  width: 100%;
  height:240px;
  border: 1px dashed #C4C4C4;
  display: block;
  padding: 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
  border-radius: 20px !important;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
}
.filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}

.filelabel .title {
color: grey;
transition: 200ms color;
width:50%;
margin-top: 15px;
}
.filelabel:hover {
border: 2px solid #1665c4;
}
.filelabel:hover i,
.filelabel:hover .title {
color: #1665c4;
}
#formFile{
  display:none;
}

.attachment-icon{background: url(../../asset/svg/icon-uplaod.svg) no-repeat center center; width:65px; height: 60px;}