
.card-itm.emp-card-itm .card{padding:20px 30px 10px; display: flex; /*justify-content: space-evenly;*/ flex-direction:row}
.emp-pic{width:120px; height: 120px; border-radius: 100%; overflow: hidden; background: #fafafa;}
.emp-pic img{object-fit: cover; width: 100%; height: 100%;}


.card-body .card-title{font-size: var(--font-size-18); font-weight: var(--font-weight-medium); }
.card-body .card-title:hover a,
.card-body .card-title a:hover{color:var(--theme-secondary-color) !important}
.card-body ul li label{font-size: var(--font-size-13); color:var(--theme-gray-light-color); font-weight: var(--font-weight-normal); margin-right:5px;}
.card-body ul li span{font-size: var(--font-size-13); color:var(--theme-base-color); font-weight: var(--font-weight-normal); }
.card-body ul li span i{font-style: inherit !important;}
.card-itm.emp-card-itm .card-action{display: flex; align-items: center; grid-gap: 0 10px;}
.emp-card-itm .btn-green{font-size: var(--font-size-16); height: 36px; justify-content: space-evenly; border-radius:10px; min-width: 150px;}
.emp-card-itm .btn-green svg path{fill:#ffffff}
.emp-card-itm .btn-green:hover svg path{fill:var(--secondary-green-color)}

.card-body ul li.court-practise{display: flex;     align-items: baseline;}
.card-body ul li.court-practise span{ white-space: pre-line; display: inline-block; line-height: normal;}


.card-right{display: flex;    align-items: end;    padding: 0; flex-direction: column;}
.emp-award{margin-top: 15px; width: 100%; text-align: right; /*max-width:150px;*/ height: auto;}
.emp-award img{/*min-width: 120px; max-width: 120px; max-height: 90px;*/ width:100%; height: 100%; object-fit: cover;}
.after-login-page .emp-award img{/*min-width: inherit; max-width: 100%; height: auto; max-height: 100%; float: right;*/}

.without-shadow .emp-award{margin-top: 0;  margin-bottom: 15px;}

.gdirectory-page .btn-green{/*display: none;*/}

.emp-card-itm .card-mdl{padding:0 15px; width: calc(100% - 320px);}
.without-shadow .emp-card-itm .card-body li span {float: none; display: block; font-weight: var(--font-weight-medium);}
.without-shadow .emp-card-itm .card-body li span.card-title-address {color: var(--theme-secondary-color); font-size: 12px; margin-bottom: 4px;}
.emp-card-itm .card-body{padding:0px}
.emp-card-itm .card-body .card-title{min-height: inherit; margin-bottom: 0;}
.ranking-badge{grid-gap:1px; margin: 3px 0 ;}
.ranking-badge-lst{    min-width: 200px; margin-right: 10px;}
.ldirectory-page .emp-card-itm .card-mdl{width:calc(100% - 335px)}
.ldirectory-page .card-right{/*position: absolute; right:20px; top:10px*/ width:315px}
.ldirectory-page .emp-award{text-align: right;}

.directory-listing-page .emp-award{max-width: 120px; height:120px; }
.directory-listing-page .emp-award img{    object-fit: contain;}
.directory-detail-page .emp-card-itm .card-body .card-title{font-size: var(--font-size-24); color:var(--theme-secondary-color); margin-bottom: 5px;}
.card-title-address{color:var(--theme-secondary-color)}
.card-title-addresscolor{color:var(--theme-secondary-color)}
.emp-card-itm .card-body li{margin:2px 0}

.emp-comp-detail{display: inline-flex; margin-top:0px}
.emp-comp-detail img{max-height: 60px;}
.emp-card-itm .emp-comp-detail .card-body{padding:0px; height:60px; display: flex; align-items: flex-start; flex-direction: column; margin-left: 10px; place-content: center;}
.emp-card-itm .emp-comp-detail .card-body .card-title{margin:0px; padding:0px; font-size: var(--font-size-14);}

.extra-info {width:calc(100% - 150px);float: right; margin-right:20px;     position: relative;    z-index: 1;}

.gdirectory-page .emp-card-itm .card-mdl{width:calc(100% - 240px)}
.extra-info-rgt .card-action{height:60px}

.social-links{grid-gap:0px; margin:5px 0 10px; min-width: 600px;}
.social-lst{width:120px; height: 36px; display: flex; align-items: center; grid-gap: 10px; place-content: center; border-radius: 10px; border:1px solid #ededed; font-size: var(--font-size-12); font-weight: var(--font-weight-medium); transition: all 0.1s ease;}
.social-fb i{font-size: var(--font-size-18); color:#385498; transition: all 0.1s ease}
.social-tt i{font-size: var(--font-size-20); color:#1C9CEA; transition: all 0.1s ease}
.social-link i{font-size: var(--font-size-20); color:#0073AF;transition: all 0.1s ease}
.social-yt i{font-size: var(--font-size-24); color:#F70000; transition: all 0.1s ease}

.social-fb:hover{background:#385498; border-color:#385498; color:#ffffff !important}
.social-tt:hover{background:#1C9CEA; border-color:#1C9CEA; color:#ffffff !important}
.social-link:hover{background:#0073AF; border-color:#0073AF; color:#ffffff !important}
.social-yt:hover{background:#F70000; border-color:#F70000; color:#ffffff !important}

.social-fb:hover i{color:#ffffff }
.social-tt:hover i{ color:#ffffff}
.social-link:hover i{color:#ffffff}
.social-yt:hover i{color:#ffffff}


.emp-exp label{font-size: vaR(--font-size-13);     color: var(--theme-gray-light-color);    font-weight: var(--font-weight-normal);}
.emp-exp span{font-weight: var(--font-weight-medium);     color: var(--theme-base-color); font-size: var(--font-size-13);}

.directory-listing-page .card-right{align-items: flex-end;}
.directory-detail-page .lawyer-avilibility .cmn-list {margin-top: 0;}
.emp-card-itm .extra-info {width: 100%; justify-content: flex-end !important;}
.emp-card-itm .extra-info .social-links {justify-content: flex-end; margin-top: 15px;}
.directory-detail-page .without-shadow .emp-award .emp_ranking {margin: 0;}
.directory-detail-page .without-shadow .directory-ranking .emp-award  {max-width: 160px;}

@media screen and (min-width:1136px) and (max-width:1480px){ 
    .emp-card-itm .emp-pic {width: 120px; height: 120px;}
}



.organigation-block{display: flex;     align-items: center;    grid-gap: 20px; width:300px}
.organigation-block label{font-size: var(--font-size-13); color:var(--theme-base-color) !important; font-weight: var(--font-weight-semibold);}
.organigation-block span{color:#9092A3; font-weight: var(--font-weight-normal); font-size: var(--font-size-13);}
.organization-address{width:calc(100% - 120px)}

.directory-listing-page .cmn-list{margin: 0 10px 0 0; text-align: right;}



.directory-listing-page .social-links{min-width: inherit}
.directory-listing-page .social-lst{border:none; width:30px; height: 30px; background-color: transparent;}


.directory-detail-page .social-links{min-width: inherit}
.directory-detail-page .social-lst{border:none; width:30px; height: 30px; background-color: transparent;}
.directory-detail-page button.social-lst{width:40px; }




.gc-designation strong{font-weight: var(--font-weight-medium); color:var(--theme-secondary-dark-bg)}
.card-body ul li.gc-other-detail span{font-weight: var(--font-weight-medium);}
