.mp-avatar {
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 50%;
  background: #0c540c;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 5px 0;
}

.posted-pic .mp-avatar{width: 100% !important; height: 100% !important;  line-height: 100% !important;  margin: 0;  display: flex;  place-content: center;  align-items: center;}