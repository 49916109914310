*{text-shadow:none; margin:0px; padding:0px}
html{font-size:var(--theme-html-font-size)}
body{font-family: var(--font-family-regular) !important; font-weight: normal !important; font-size: var(--theme-body-font-size) !important;}

a{text-decoration: none !important; color:var(--theme-gray-text-color); cursor: pointer;}
a:hover,
a:focus{color:var(--common-text-color) !important; outline:none !important}

ul, ol{margin:0px !important; padding:0px !important;}

img{max-width: 100%; max-height: 100%;}

.form-select:focus, .form-select:hover,
select:hover, select:focus,
input:hover, input:focus,
button:hover, button:focus{box-shadow: none !important; outline:none !important}

button{outline:none !important; box-shadow:none !important}

::-webkit-scrollbar {  width: 5px; height:7px; max-width:7px}
::-webkit-scrollbar-track {  background: #f1f1f1; }
::-webkit-scrollbar-thumb {  background: #888888; }
::-webkit-scrollbar-thumb:hover {  background: #555555; }
::-webkit-scrollbar {  width: 5px; height:7px; max-width:7px}
::-webkit-scrollbar-track {  background: #f1f1f1; }
::-webkit-scrollbar-thumb {  background: #888888; }
::-webkit-scrollbar-thumb:hover {  background: #555555; }

li{list-style:none;}

.login-form{width:420px; height: auto; background: #FFFFFF;     box-sizing: border-box;   box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06);  border-radius: 20px; padding:25px}
.login-form h1{font-size: var(--font-size-22); line-height: 27px; color:var(--theme-base-color); font-weight: var(--font-weight-semibold); text-align: center;}

.login-form form{width:100%;}

.form-row label{font-size:var(--common-label-size); color:var(--common-label-color); margin-bottom: 7px !important;}
.form-row .form-control{height:var(--common-input-height); border:1px solid var(--common-input-border); border-radius:var(--common-border-radius)}
.form-row .form-control.custom-textarea{height:inherit;}
.form-row .form-input-div{position: relative;}
.form-row .form-input-div i{position: absolute; right:15px; bottom: 10px; font-size: var(--font-size-16); color:#999}

::-webkit-input-placeholder {  opacity: 0.4 !important}
:-ms-input-placeholder {   opacity: 0.4 !important;}
::placeholder {  opacity: 0.4 !important;}

.form-textarea{height: 150px !important;}

.form-control[type="loader"]:disabled{background: none; border-radius: var(--common-border-radius) !important;}

.loading-icon{display: none !important;}

.joinnow-action{max-width: 320px; margin:20px auto auto  !important;  }
.joinnow-action .btn-joinnow{height: 50px !important; line-height: 50px !important; width:auto; padding:0px; background:var(--theme-secondary-color) !important; 
  font-size: var(--font-size-22) !important;
   font-weight: var(--font-weight-medium) !important;color:white ;width:100%; display:flex; border-radius: 50px !important; 
   height:60px; border-radius:10px; align-items: center; place-content: center; grid-gap:10px}
.joinnow-action .btn-joinnow:hover{background: var(--theme-secondary-dark-bg)  !important; border-color:transparent !important; color:var(--color-white) !important}


.signin-btn{width: 320px !important; margin:0px auto auto  !important; border-radius: 50px !important; height: 50px !important; line-height: 50px !important; width:auto; padding:0px; 
  background:var(var(--secondary-green-color)) !important; font-size: var(--font-size-22) !important; font-weight: var(--font-weight-medium) !important;color:white ;
  width:100%; display:flex; height:60px; border-radius:10px; align-items: center; place-content: center; grid-gap:10px}
  .signin-btn i{font-size: 28px;}
.signin-btn:hover{background: var(--theme-secondary-dark-bg) !important; border-color:transparent !important; color:var(--color-white) !important}

.forgot-link{margin:20px 0 0 !important}
.forgot-link a{font-size: var(--font-size-18) !important; color:var(--theme-secondary-color); font-weight: var(--font-weight-semibold);}

.PhoneInput{float: left; width:100%; position: relative; height:36px; background: #fff; border:0px }
.PhoneInputCountry{background: #f5f5f5; border: none;   padding: 0 5px 0 10px;  margin: 0;  border-radius: 0;
  position: absolute;  left: 0; height: 42px;  bottom: -3px; border-right: 1px solid var(--common-border-color); min-width: 60px; border-radius: 10px 0 0 10px;}
.PhoneInputInput{text-indent: 60px; height:36px; border:0}
.PhoneInputCountryIcon{margin-right:5px}
.api-error{    margin-top: -21px;  margin-bottom: 20px;  font-size: 15px;  font-weight: var(--font-weight-medium);}

.rbt.mdi:before { position: absolute;  right: 15px;  top: 11px;  z-index: 1;   color: #666666;    pointer-events: none;}

.form-input-div .PhoneInputInput{    height: var(--common-input-height);  border: 1px solid var(--common-input-border);   border-radius: 10px; padding-left: 10px;}

.form-row{position: relative; display: flex; flex-direction: column;}
.input-err{color:var(--red-color); font-size: var(--font-size-11); padding:5px; position: absolute; bottom:-25px; right:0}
.rbt-highlight-text{font-weight: normal;}

.resetpassowrd-header{display: none !important;}

.terms-link{text-decoration: underline !important; color:blue;}

.CookieConsent{    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 8%); }
.cookie-icon {margin-top: -5rem; margin-bottom: 1rem; position: relative; width: 130px; margin-left: auto;
  margin-right: auto;} 
.cookie-text{ width: 100%; height: 170px; display: flex; place-content: center; align-items: center; font-size: 18px;} 

.common-content-page li{list-style: inherit; border:0px !important; float: left; width:100%}

button:disabled:hover, button:disabled,
.btn-primary.disabled:hover, .btn-primary:disabled:hover,
.btn-primary.disabled, .btn-primary:disabled{background: #999 !important;}