.react-bootstrap-table table thead{background:var( --table-heading-bg)}
.react-bootstrap-table table thead th{ padding: 5px !important;   border: 1px solid var(--common-border-color) !important; border-right: 1px solid var(--common-border-color) !important;   border-bottom: 1px solid transparent !important;   height: 35px;
    align-items: center;    font-size: var(--font-size-13) !important;    line-height: normal; vertical-align: middle; font-weight: var(--font-weight-medium);}

.react-bootstrap-table table tbody td{ padding: 0px 20px !important;   border-top: 1px solid var(--common-border-color) !important;   border-bottom: 1px solid var(--common-border-color) !important;    height: 70px;
    align-items: center;    font-size: var(--font-size-14) !important; color:var(--theme-base-color);   line-height: normal; vertical-align: middle;}
.react-bootstrap-table table tbody tr:nth-child(2n) td{background-color: var(--even-row-bg);}

.tableTopBlock{display: flex; width: 100%; place-content: flex-end; padding: 5px 0; grid-gap:10px; margin-top: -60px;  padding-right: 100px !important; margin-bottom: 20px;}
.actionBtn{display: flex; grid-gap:10px}

.btn-refresh{width:30px; height: 30px; background: #fff;   border: 1px solid var(--pager-icon-brdr2) ;   color: var(--pager-icon-color); border-radius: var(--common-input-border-radius);}
.btn.react-bs-table-csv-btn{ height: 30px; line-height: 28px; background: #fff;   border: 1px solid var(--pager-icon-brdr2);   color: var(--pager-icon-color); border-radius: var(--common-input-border-radius); padding: 0 10px !important; font-size: var(--font-size-13); display: flex; align-items: center;}
.btn-refresh:hover,
.btn.react-bs-table-csv-btn:hover{background: var(--theme-base-color) !important; border-color:var(--theme-base-color); color:var(--color-white) !important}
.search-label{position: relative;}
.search-label input{height: 30px !important; border-color: var(--pager-icon-brdr2) !important;   background: none !important;    padding: 0px 0 0 40px !important;
    box-sizing: border-box;    border-radius: var(--common-input-border-radius); background: var(--color-white) !important;}
.search-label:before{         font-family: "Material Design Icons";    content: "\F0349";    font-size: var(--font-size-18);       color: var(--pager-icon-color) !important;
position: absolute;  left: 10px; top: 0;  bottom: 0; margin: auto;  height: var(--common-button-height) !important;     line-height: var(--common-button-height) !important;}
    
.react-bootstrap-table{height: calc(100vh - 210px);}    

.react-bootstrap-table table tbody td a{color:var(--theme-base-color); font-weight: var(--font-weight-medium);}
.custom-listview-action{display: flex; justify-content: flex-end; grid-gap:5px}
.custom-listview-action .btn{padding: 0;  margin:0; color:#b5b5c3}

.react-bootstrap-table-pagination{    border-top: 1px solid var(--common-border-color);    display: flex;
    align-items: center;    height: 70px;    padding: 0 20px;    position: relative;}
.react-bs-table-sizePerPage-dropdown{position: inherit; float:left;}

.react-bs-table-sizePerPage-dropdown .dropdown-toggle:focus,
.react-bs-table-sizePerPage-dropdown .dropdown-toggle:hover,
.react-bs-table-sizePerPage-dropdown .dropdown-toggle{border:1px solid var(--common-border-color) !important; background:var(--color-light-gray) !important; 
    padding:0 7px; height:35px; line-height:normal; color:var(--color-gray66) !important; margin-right: 10px; display: flex; align-items: center; font-size:var(--font-size-14); font-weight: var(--font-weight-medium);}

.react-bootstrap-table-pagination-total{color:var(--color-gray66); line-height: 32px; font-size:var(--font-size-14); font-weight: var(--font-weight-medium);}
.react-bootstrap-table-pagination-list .page-item .page-link{border:1px solid var(--common-border-color) !important; background:var(--color-light-gray) !important; 
    padding:0 7px; height:35px !important; width:35px !important; line-height:normal; color:var(--color-gray66); font-size:var(--font-size-14); font-weight: var(--font-weight-medium); display: flex; align-items: center; place-content: center;}
.react-bootstrap-table-pagination-list .page-item.active .page-link{border:1px solid var(--common-border-color) !important; background:var(--theme-base-color) !important; }
.pagination{margin-bottom: 0px;}

.react-bs-table-sizePerPage-dropdown .dropdown-menu{bottom:37px; border-radius: 0px; padding:0px; margin: 0px; width:auto; min-width: 40px;}
.react-bs-table-sizePerPage-dropdown .dropdown-menu a{height: 30px; line-height: 30px; padding:0 10px; border-bottom: 1px solid var(--common-border-color); font-size: var(--font-size-13); font-weight: var(--font-weight-medium);}
.react-bs-table-sizePerPage-dropdown .dropdown-menu a:hover{background: var(--theme-base-color); color:var(--color-white) !important}
.react-bs-table-sizePerPage-dropdown .dropdown-menu a:last-child{border:0px}

.proposal-request-container .tableTopBlock{margin-top: -100px; padding-right:0px !important}